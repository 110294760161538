<template>
  <div class="profile-menu__content">
    <profile-menu-item item-text="Profile" icon-slug="user" router-link="/profile" />
    <profile-menu-item
      v-if="userStore.isCurrentAdmin"
      item-text="Admin Settings"
      icon-slug="gear"
      href="/admin"
    />
    <profile-menu-item icon-slug="circle-info" item-text="About" router-link="/about" />
    <profile-menu-item
      v-if="userStore?.currentUser?.isBeingImpersonated"
      item-text="Unimpersonate"
      icon-slug="right-from-bracket"
      href="/unimpersonate"
    />
    <profile-menu-item v-else item-text="Logout" icon-slug="right-from-bracket" href="/logout" />
  </div>
</template>

<script setup lang="ts">
import ProfileMenuItem from './ProfileMenuItem.vue'
import { useUserStore } from '@/stores/UserStore'

const userStore = useUserStore()
</script>

<style scoped lang="scss">
.profile-menu {
  &__content {
    position: absolute;
    right: 0;
    top: 3.75rem;
    width: 12.75rem;
    background: var(--primary-brand-color);
    overflow: hidden;
    border-radius: 0 0 var(--y-radius-md) var(--y-radius-md);
    box-shadow: var(--y-shadow-grey-md);
    z-index: 9999;
  }
}
@media (min-width: 640px) {
  .profile-menu__content {
    right: 3rem;
  }
}
</style>
