<template>
  <div class="title-row inline-flex justify-between items-center">
    <h2 class="mb-4">Upcoming Sessions</h2>
    <UpcomingSessionsSearch class="mb-4" />
  </div>
</template>
<script setup lang="ts">
import { useUserStore } from '@/stores/UserStore'
import UpcomingSessionsSearch from '@/components/UpcomingSessions/UpcomingSessionsSearch.vue'

const userStore = useUserStore()
</script>
<style lang="scss" scoped>
.title-row {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}
</style>
