<template>
  <div
    class="user-schedule__mobile-calendar isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden"
  >
    <button
      v-for="day in days"
      :key="day.date"
      type="button"
      :class="[
        day.isCurrentMonth
          ? 'user-schedule__mobile-calendar--current-month'
          : 'user-schedule__mobile-calendar--non-current-month',
        (day.isSelected || day.isToday) && 'user-schedule__mobile-calendar--focused',
        day.isSelected && 'user-schedule__mobile-calendar--selected',
        !day.isSelected && day.isToday && 'user-schedule__mobile-calendar--today-and-not-selected',
        !day.isSelected &&
          day.isCurrentMonth &&
          !day.isToday &&
          'user-schedule__mobile-calendar--current-month--not-selected-or-today',
        !day.isSelected &&
          !day.isCurrentMonth &&
          !day.isToday &&
          'user-schedule__mobile-calendar--not-selected-or-current-month',
        'flex h-14 flex-col px-2 py-1 focus:z-10'
      ]"
      @click="setNewSelectedDay(day)"
    >
      <time
        :datetime="day.date"
        :class="[
          day.isSelected && 'flex items-center justify-center rounded-full',
          day.isSelected && day.isToday && 'bg-indigo-600',
          day.isSelected && !day.isToday && 'bg-gray-900',
          'mr-auto'
        ]"
      >
        {{ day.date.split('-').pop()?.replace(/^0/, '') }}
      </time>
      <span class="sr-only">{{ day.panelSessions.length }} sessions</span>
      <span v-if="day.panelSessions.length > 0" class="-mx-0.5 mb-auto flex flex-wrap">
        <span
          v-for="panelSession in day.panelSessions"
          :key="panelSession.id"
          :class="[
            'mx-0.5 mt-1 h-1.5 w-1.5 rounded-full bg-gray-400 user-schedule__mobile-calendar__session-indicator',
            panelSession.nowLive
              ? 'user-schedule__mobile-calendar__session-indicator--live'
              : 'user-schedule__mobile-calendar__session-indicator--reg'
          ]"
        />
      </span>
    </button>
  </div>
</template>
<script setup lang="ts">
import type { UserScheduleDay } from '@/types/UserScheduleDay'
import { scheduleSelectedDate } from '@/components/UserSchedule/userScheduleComposables'
import moment from 'moment'

defineProps<{
  days: UserScheduleDay[]
}>()

const setNewSelectedDay = function (day: UserScheduleDay) {
  scheduleSelectedDate.value = moment(day.date)
}
</script>
<style lang="scss">
.user-schedule__calendar-box {
  border-radius: var(--y-radius-md);
  overflow: hidden;
}

.user-schedule__mobile-calendar {
  border: 1px solid var(--ych-grey-400);
  border-radius: var(--y-radius-md);
  overflow: hidden;

  &--current-month {
    color: var(--secondary-brand-color);
    background: var(--ych-white);

    &:hover {
      background: var(--ych-grey-100);
    }
  }

  &--focused {
    font-weight: bold;
  }

  &--today-and-not-selected {
    background: var(--ych-grey-200);

    &:hover {
      background: var(--ych-grey-100);
    }
  }

  &--selected {
    background: var(--ych-grey-400);
    color: var(--primary-accent-color);

    &:hover {
      background: var(--ych-grey-400);
      color: var(--primary-accent-color);
    }
  }

  &--not-selected-or-current-month {
    background-color: var(--ych-grey-600);
    color: var(--primary-accent-color);
  }

  &__session-indicator {
    border-radius: 100%;

    &--reg {
      background-color: var(--secondary-brand-color);
    }

    &--live {
      background-color: var(--primary-brand-color);
    }

    &--null {
      background-color: var(--ych-kraft);
    }
  }
}
</style>
