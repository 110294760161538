<template>
  <div class="session-item gap-2 p-3 flex items-center flex-col">
    <div class="flex flex-row justify-between items-center w-full flex-wrap gap-1">
      <div class="flex top-row items-center flex-wrap">
        <h4 class="bullet-separated">
          <y-icon
            :icon="downloadingIcalFile ? 'calendar-check' : 'calendar'"
            v-if="props.panelSession.isAdded"
            @click="downloadIcalFile"
            class="mr-1"
          ></y-icon>
          {{ date }}
        </h4>
        <h4 class="bullet-separated">{{ time }}</h4>
        <h4 class="bullet-separated">{{ panelName }}</h4>
      </div>
      <div
        v-if="props.panelSession.types.length !== 0"
        class="session-item__sample-icons flex gap-2 items-center"
      >
        <div>Samples:</div>
        <div class="flex gap-1 items-center">
          <y-icon
            icon="beer-mug"
            v-if="hasBeer"
            font-size="1rem"
            title="This session includes beer samples"
          />
          <i
            class="fa-kit fa-variety-cone"
            v-if="hasHops"
            title="This session includes hops samples"
          ></i>
          <y-icon
            icon="circle-question-solid"
            v-if="hasOther"
            font-size="1rem"
            :title="'This session includes ' + otherType.toLowerCase() + ' samples'"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-between items-center w-full flex-wrap gap-2">
      <div class="flex flex-col gap-1">
        <div class="location flex gap-1.5 content-center">
          {{ location }}<i class="fa-solid fa-location-dot" />
        </div>
        <div class="flex gap-1">
          <small v-if="props.panelSession.maxPanelists"
            >{{ openSlots }}/{{ props.panelSession.maxPanelists }} slots available</small
          >
          <small v-if="props.panelSession.adminLink">
            <a :href="props.panelSession.adminLink as string">Manage Session</a>
          </small>
        </div>
      </div>
      <div class="flex justify-end items-center">
        <div class="button-container grid col-span-1 content-center justify-end">
          <PanelSessionButton :panel-session="props.panelSession" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import moment from 'moment'

import { YIcon } from '@yakimachief/ych-ts-component-library'
import type { PanelSession } from '@/types/PanelSession'
import PanelSessionButton from '@/components/UpcomingSessions/PanelSessionButton.vue'

/**
 * Properties
 */
const props = defineProps<{
  panelSession: PanelSession
}>()

const downloadingIcalFile = ref(false)

/**
 * Computed Values
 */
const panelName = computed(() => {
  return props.panelSession.panelName
})

const openSlots = computed(() => {
  if (props.panelSession.maxPanelists === null) return false
  return props.panelSession.maxPanelists - props.panelSession.panelistCount
})

const date = computed(() => {
  return moment(props.panelSession.startTime).format('MMM D, YYYY')
})
const time = computed(() => {
  return moment(props.panelSession.startTime).format('h:mm a')
})

function downloadIcalFile() {
  window.location.href = props.panelSession.icalEventUrl
  downloadingIcalFile.value = true

  setTimeout(function () {
    downloadingIcalFile.value = false
  }, 1500)
}

const hasBeer = computed(() => {
  return props.panelSession.types.includes('beer')
})
const hasHops = computed(() => {
  return props.panelSession.types.includes('hops')
})
const hasOther = computed(() => {
  return otherTypes.value.length > 0
})
const otherTypes = computed(() => {
  return props.panelSession.types.filter((type) => {
    return !(type === 'hops' || type === 'beer')
  })
})

const otherType = computed(() => {
  return otherTypes.value.join(', ')
})

const location = computed(() => {
  return props.panelSession.location?.name
})
/**
 * Hanlder functions
 */
</script>

<style lang="scss" scoped>
.session-item {
  background: var(--ych-grey-100);
  border-radius: var(--y-radius-md);
  box-shadow: var(--y-shadow-grey-sm);

  .bullet-separated {
    display: inline-flex;

    &:after {
      content: '\2022';
      font-size: var(--y-font-size-md);
      color: var(--secondary-brand-color);
      padding-right: 0.25rem;
      padding-left: 0.25rem;
    }

    &:last-of-type:after {
      content: '';
    }
  }

  .location i.fa-location-dot {
    margin-top: 2px;
  }
}
</style>
