import type { Organization } from '@/types/Organization'
import { useOrganizationStore } from '@/stores/OrganizationStore'

const organizationFactory = function (organizationData: any): Organization {
  return {
    uuid: organizationData.uuid,
    name: organizationData.name,
    enabled: organizationData.enabled,
    createdAt: organizationData.created_at,
    updatedAt: organizationData.updated_at,
    theme: {
      primaryBrandColor: organizationData.theme['primary-brand-color'],
      secondaryBrandColor: organizationData.theme['secondary-brand-color'],
      primaryAccentColor: organizationData.theme['primary-accent-color'],
      secondaryAccentColor: organizationData.theme['secondary-accent-color']
    }
  }
}

export default organizationFactory
