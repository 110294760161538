<template>
  <div class="sessions__filters">
    <div class="sessions__filters__sortable">
      <UpcomingSessionsSortControl column="date-time">Date/Time</UpcomingSessionsSortControl>
      <UpcomingSessionsSortControl column="session-type">Session Type</UpcomingSessionsSortControl>
      <UpcomingSessionsSortControl column="location" v-if="props.showLocation"
        >Location</UpcomingSessionsSortControl
      >
    </div>
    <div class="sessions__filters__toggles">
      <div v-if="showBeerToggle">
        <label for="sessionBeerToggle">Beer</label>
        <y-toggle v-model="showBeer" :has-icon="true" id="sessionBeerToggle" />
      </div>
      <div v-if="showHopsToggle">
        <label for="sessionHopsToggle">Hops</label>
        <y-toggle v-model="showHops" :has-icon="true" id="sessionHopsToggle" />
      </div>
      <div v-if="showOtherToggle">
        <label for="sessionOtherToggle">Other</label>
        <y-toggle v-model="showOther" :has-icon="true" id="sessionOtherToggle" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
export interface Props {
  showLocation?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  showLocation: true
})

import UpcomingSessionsSortControl from './UpcomingSessionsSortControl.vue'
import { YToggle } from '@yakimachief/ych-ts-component-library'
import type { PanelSessionsFilterType } from './upcomingSessionsComposables'
import { sessionsFilterSettings } from './upcomingSessionsComposables'
import { computed, ref, watch } from 'vue'
import { usePanelSessionStore } from '@/stores/PanelSessionStore'

const showHops = ref(!sessionsFilterSettings.value.filterType.includes('hops'))
const showBeer = ref(!sessionsFilterSettings.value.filterType.includes('beer'))
const showOther = ref(!sessionsFilterSettings.value.filterType.includes('other'))

// Update composable filter settings based on toggle state
watch([showBeer, showHops, showOther], () => {
  let panelSessionProductFilter: PanelSessionsFilterType[] = []

  if (!showHops.value) {
    panelSessionProductFilter.push('hops')
  }
  if (!showBeer.value) {
    panelSessionProductFilter.push('beer')
  }
  if (!showOther.value) {
    panelSessionProductFilter.push('other')
  }

  sessionsFilterSettings.value.filterType = panelSessionProductFilter
})

//Show toggles if available sessions is diverse enough
const panelSessionStore = usePanelSessionStore()
const showBeerToggle = computed(() => {
  return panelSessionStore.availablePanelSessions.some((panelSession) => {
    return panelSession.types.includes('beer')
  })
})
const showHopsToggle = computed(() => {
  return panelSessionStore.availablePanelSessions.some((panelSession) => {
    return panelSession.types.includes('hops')
  })
})
const showOtherToggle = computed(() => {
  return panelSessionStore.availablePanelSessions.some((panelSession) => {
    if (panelSession.types.length === 0) return true
    const types = panelSession.types.filter((type) => {
      return !(type === 'beer' || type === 'hops')
    })
    return types.length > 0
  })
})
const showToggles = computed(() => {
  let toggleCount = 0
  if (showOtherToggle.value) toggleCount++
  if (showHopsToggle.value) toggleCount++
  if (showBeerToggle.value) toggleCount++
  if (toggleCount > 1) return true
})
</script>
<style lang="scss">
.sessions__filters {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  border-bottom: 1px solid var(--ych-grey-200);
  padding: 0 0.25rem 0 0.25rem;

  &__sortable {
    display: inline-flex;
    align-items: center;
    padding-bottom: 0.5rem;

    div {
      padding-right: 1rem;
      padding-left: 1rem;
      cursor: pointer;
    }

    div:not(:last-child) {
      border-right: 1px solid var(--ych-grey-400);
    }

    div:first-child {
      padding-left: 0;
    }

    a {
      text-decoration: none;
      color: var(--secondary-brand-color);
    }
  }

  &__toggles {
    display: inline-flex;
    gap: 1rem;
    padding-bottom: 0.5rem;

    div {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
}
</style>
