<template>
  <li
    :class="[
      panelSession.nowLive
        ? 'user-schedule__day__session-list__item--live'
        : 'user-schedule__day__session-list__item--reg',
      'user-schedule__day__session-list__item'
    ]"
    tabindex="0"
    @click="dialogOpen = true"
  >
    <small class="user-schedule__day__session-list__item__content">
      <span class="user-schedule__day__session-list__item__content__time"
        >{{ moment(panelSession.startTime).format('h:mm a') }} •</span
      >
      <span
        class="user-schedule__day__session-list__item__content__live"
        v-if="panelSession.nowLive"
        >LIVE •</span
      >
      <span class="user-schedule__day__session-list__item__content__type">{{
        panelSession.panelName
      }}</span>
    </small>
    <UserSchedulePanelSessionDialog :panel-session="panelSession" v-model:open="dialogOpen" />
  </li>
</template>
<script setup lang="ts">
import moment from 'moment'
import type { PanelSession } from '@/types/PanelSession'
import { ref, computed } from 'vue'
import UserSchedulePanelSessionDialog from '@/components/UserSchedule/UserSchedulePanelSessionDialog.vue'
import { getPanelTypeLabel } from '@/components/UpcomingSessions/upcomingSessionsComposables'

const props = defineProps<{
  panelSession: PanelSession
}>()

const dialogOpen = ref(false)
</script>
<style lang="scss">
.user-schedule__day__session-list {
  &__item {
    cursor: pointer;
    height: 1rem;
    box-shadow: inset -2px 0 6px rgba(22, 22, 22, 0.25);
    overflow: hidden;
    position: relative;

    &__content {
      padding: 0.125rem 0.375rem;
      position: absolute;
      left: 0;
      font-weight: var(--y-font-weight-md);
      display: inline-flex;
      gap: 0.2rem;
      min-width: 100%;
      transition: all 1s ease-out;
      white-space: nowrap;

      &:hover {
        position: absolute;
        left: 100%;
        transform: translateX(-100%);
      }

      &__type {
        display: inline-flex;
        text-transform: capitalize;
        white-space: nowrap;
      }
    }
  }
}
</style>
