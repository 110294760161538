<template>
  <div class="sessions__list" v-if="panelSessions.length > 0">
    <UpcomingSessionItem
      v-for="panelSession in panelSessions"
      :panel-session="panelSession"
      :key="'panel-session-' + panelSession.id"
    />
  </div>
  <div v-else>
    <p class="alert alert-info">There are currently no available sessions</p>
  </div>
  <div class="inner-shadow" v-if="panelSessions.length > 3"></div>
</template>
<script setup lang="ts">
import UpcomingSessionItem from '@/components/UpcomingSessions/UpcomingSessionsItem.vue'
import { usePanelSessionStore } from '@/stores/PanelSessionStore'
import { sessionsFilterSettings } from '@/components/UpcomingSessions/upcomingSessionsComposables'
import { computed } from 'vue'
import type { PanelSession } from '@/types/PanelSession'
import type {
  PanelSessionsSortField,
  PanelSessionsSortDirection,
  PanelSessionsFilterType
} from '@/components/UpcomingSessions/upcomingSessionsComposables'

const panelSessionStore = usePanelSessionStore()

const panelSessions = computed(() => {
  let availablePanelSessions: PanelSession[] = [...panelSessionStore.availablePanelSessions]
  const field: PanelSessionsSortField = sessionsFilterSettings.value.currentSortField
  const direction: PanelSessionsSortDirection = sessionsFilterSettings.value.currentSortDirection
  const filter: PanelSessionsFilterType[] = sessionsFilterSettings.value.filterType
  if (filter.length > 0) {
    availablePanelSessions = availablePanelSessions.filter((panelSession) => {
      if (filter.includes('beer')) {
        if (panelSession.types.includes('beer')) {
          return false
        }
      }
      if (filter.includes('hops')) {
        if (panelSession.types.includes('hops')) {
          return false
        }
      }
      if (filter.includes('other')) {
        if (panelSession.types.length === 0) return false
        const otherTypes = panelSession.types.filter((type) => {
          return !(type === 'hops' || type === 'beer')
        })
        if (otherTypes.length > 0) {
          return false
        }
      }
      return true
    })
  }

  availablePanelSessions = availablePanelSessions.filter((panelSession) => {
    return panelSession.panelName
      .toLowerCase()
      .includes(sessionsFilterSettings.value.search.toLowerCase())
  })

  // Default sort date asc, provides a secondary sort for other fields
  availablePanelSessions.sort((a, b) => a.startTime.getTime() - b.startTime.getTime())
  if (field === 'date-time') {
    if (direction === 'desc') {
      availablePanelSessions.sort((b, a) => a.startTime.getTime() - b.startTime.getTime())
    }
  } else if (field === 'session-type') {
    if (direction === 'asc') {
      availablePanelSessions.sort((a, b) => {
        if (a.types.length === 1 && b.types.length === 1) {
          return a.types[0].localeCompare(b.types[0])
        }
        if (a.types.length === 1 && b.types.length !== 1) {
          return -1
        }
        if (a.types.length !== 1 && b.types.length === 1) {
          return 1
        }
        return 0
      })
    } else {
      availablePanelSessions.sort((b, a) => {
        if (a.types.length === 1 && b.types.length === 1) {
          return a.types[0].localeCompare(b.types[0])
        }
        if (a.types.length === 1 && b.types.length !== 1) {
          return -1
        }
        if (a.types.length !== 1 && b.types.length === 1) {
          return 1
        }
        return 0
      })
    }
  } else if (field === 'location') {
    if (direction === 'asc') {
      availablePanelSessions.sort((a, b) => a.location.name.localeCompare(b.location.name))
    } else {
      availablePanelSessions.sort((b, a) => a.location.name.localeCompare(b.location.name))
    }
  }
  return availablePanelSessions
})
</script>
<style lang="scss" scoped>
.sessions__list {
  padding-top: 0.5rem;
  padding-bottom: 0.75rem;
  position: relative;
  min-height: 88px;
  height: 100%;
  max-height: 25rem;
  width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  scrollbar-color: #70758a transparent;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  &::-webkit-scrollbar {
    width: 0.75rem;
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
    border: none;
    width: 0.75rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--ych-grey-400);
    border-radius: 0.5rem;
    border: 2px solid transparent;
    background-clip: content-box;
  }
}

.inner-shadow {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 0.5rem;
  pointer-events: none;

  background: -webkit-linear-gradient(90deg, rgba(22, 22, 22, 0.25), transparent);
  background: linear-gradient(0deg, rgba(22, 22, 22, 0.25), transparent);
}
</style>
