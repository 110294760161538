<script setup lang="ts">
import { computed, ref } from 'vue'
import { useUserStore } from '@/stores/UserStore'

const userStore = useUserStore()
const loading = ref(true)

userStore.fetchUserValidation().finally(() => {
  loading.value = false
})

const metrics = computed(() => {
  return userStore.currentUserValidation
})
</script>

<template>
  <div class="validation mb-4">
    <div class="validation__group">
      <h4>Beer:</h4>
      <div
        :class="[
          metrics?.beerValidated
            ? 'validation__group__icon--green'
            : 'validation__group__icon--red',
          'validation__group__icon'
        ]"
      >
        <i v-if="metrics?.beerValidated" class="fa-solid fa-check" />
        <i v-if="!metrics?.beerValidated" class="fa-solid fa-xmark" />
      </div>
      <div v-if="metrics?.beerValidated" class="validation_group_text">Validated</div>
      <div v-if="!metrics?.beerValidated" class="validation_group_text">Not Validated</div>
    </div>
    <div class="validation__group">
      <h4>Hops:</h4>
      <div
        :class="[
          metrics?.hopsValidated
            ? 'validation__group__icon--green'
            : 'validation__group__icon--red',
          'validation__group__icon'
        ]"
      >
        <i v-if="metrics?.hopsValidated" class="fa-solid fa-check" />
        <i v-if="!metrics?.hopsValidated" class="fa-solid fa-xmark" />
      </div>
      <div v-if="metrics?.hopsValidated" class="validation_group_text">Validated</div>
      <div v-if="!metrics?.hopsValidated" class="validation_group_text">Not Validated</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.validation {
  display: inline-flex;
  align-items: center;

  &__group {
    display: inline-flex;
    align-items: center;
    padding: 0 0.5rem;
    gap: 0.5rem;

    &__icon {
      height: 1.25rem;
      width: 1.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      color: var(--ych-white);
      font-size: 1rem;

      &--green {
        background: var(--ych-green);
      }

      &--red {
        background: var(--primary-brand-color);
      }
    }
  }
}
</style>
