import './assets/main.scss'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import { init as initFullStory, FullStory } from '@fullstory/browser'
import { createGtm } from '@gtm-support/vue-gtm'
import { useUserStore } from '@/stores/UserStore'
import type { User } from '@/types/User'
import { ref } from 'vue'
import { useOrganizationStore } from '@/stores/OrganizationStore'

const app = createApp(App)
app.use(createPinia())
app.use(router)
app.mount('#app')

const userStore = useUserStore()
const organizationStore = useOrganizationStore()
const user = ref(null as User | null)

userStore.fetchUser().finally(() => {
  user.value = userStore.currentUser
  organizationStore.applyTheme()
})

/**
 * FullStory
 */
// @todo: replace orgID with .env call
initFullStory({ orgId: 'D89WC' })

// These variables should allow for session replays in FullStory
const startOfPlayback = FullStory('getSession')
const playbackAtThisMomentInTime = FullStory('getSession', { format: 'url.now' })

if (user.value) {
  FullStory('setProperties', {
    type: 'user',
    properties: {
      displayName: user.value?.firstName + ' ' + user.value?.lastName,
      email: user.value?.email
    }
  })
}
app.config.globalProperties.$FullStory = FullStory

/**
 * Google Tag Manager (GTM)
 */
// @todo: replace GTM Container ID with .env call
app.use(
  createGtm({
    id: 'GTM-K54RXCMQ',
    vueRouter: router
  })
)
