import { defineStore } from 'pinia'
import axios from 'axios'
import type { Leaderboard } from '@/types/Leaderboard'
import router from '@/router'
import { errorToast } from '@/stores/ToasterStore'

export const useLeaderboardStore = defineStore('LeaderboardStore', {
  state: () => ({
    leaderboards: [] as Leaderboard[]
  }),

  actions: {
    async fetchLeaderboards() {
      this.leaderboards = []
      return axios
        .get('/api/leaderboards')
        .then((response) => {
          response.data.data.forEach((leaderboard: any) => {
            this.leaderboards.push({
              id: leaderboard.id,
              name: leaderboard.name
            })
          })
        })
        .catch((error) => {
          switch (error.response?.status) {
            case 401:
              router.push('/login')
              break
            default:
              if (error.response?.data?.message)
                errorToast(error.message, error.response.data.message)
              else errorToast(error.name, error.message)
              console.error(error)
          }
          if (error.response) return error.response
        })
        .finally(() => {
          // always executed
        })
    }
  }
})
