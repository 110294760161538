import { defineStore } from 'pinia'
import axios from 'axios'
import type { Timezone } from '@/types/Timezone'
import router from '@/router'
import { errorToast } from '@/stores/ToasterStore'

type TimezoneStoreData = {
  timezones: Array<Timezone>
  currentTimezone: Timezone
}
export const useTimezoneStore = defineStore('TimezoneStore', {
  state: () => ({
    timezones: [] as Timezone[],
    currentTimezone: null as Timezone | null
  }),

  actions: {
    async fetchTimezones() {
      return axios
        .get('/api/timezones')
        .then((response) => {
          response.data.data.forEach((timezone: any) => {
            this.timezones.push({
              id: timezone.id,
              descriptor: timezone.descriptor,
              label: timezone.label,
              created_at: timezone.created_at
            })
          })
        })
        .catch((error) => {
          switch (error.response?.status) {
            case 401:
              router.push('/login')
              break
            default:
              if (error.response?.data?.message)
                errorToast(error.message, error.response.data.message)
              else errorToast(error.name, error.message)
              console.error(error)
          }
          if (error.response) return error.response
        })
    },
    async fetchTimezone(timezoneDescriptor: string) {
      return axios
        .get('/api/timezones/' + timezoneDescriptor.replace('/', '-'))
        .then((response) => {
          this.currentTimezone = {
            id: response.data.data.timezone.id,
            descriptor: response.data.data.timezone.descriptor,
            label: response.data.data.timezone.label,
            created_at: response.data.data.timezone.created_at
          }
        })
        .catch((error) => {
          switch (error.response?.status) {
            case 401:
              router.push('/login')
              break
            default:
              if (error.response?.data?.message)
                errorToast(error.message, error.response.data.message)
              else errorToast(error.name, error.message)
              console.error(error)
          }
          if (error.response) return error.response
        })
    }
  }
})
