import { defineStore } from 'pinia'
import axios from 'axios'
import { useRoute } from 'vue-router'
import type { User } from '@/types/User'
import type { UserMetrics } from '@/types/UserMetrics'
import router from '@/router'
import type { UserNote } from '@/types/UserNote'
import type { ReferenceStandard } from '@/types/ReferenceStandard'
import { computed } from 'vue'
import useLocalStorage from './LocalStorageStore'
import moment from 'moment-timezone'
import useToasterStore from './ToasterStore'
import { useTimezoneStore } from './TimezoneStore'
import type { Timezone } from '@/types/Timezone'
import type { UserValidation } from '@/types/UserValidation'
import { useOrganizationStore } from '@/stores/OrganizationStore'
import organizationFactory from '@/stores/Factories/OrganizationFactory'
import type { RecentRepeatedMeasure } from '@/types/RecentRepeatedMeasure'

type UserStoreDataLoadingInfo = {
  user: boolean
  userFetching: boolean
  metrics: boolean
  metricsFetching: boolean
  notes: boolean
  notesFetching: boolean
  referenceStandards: boolean
  referenceStandardsFetching: boolean
  recentRepeatedMeasures: boolean
  recentRepeatedMeasuresFetching: boolean
  validation: boolean
  validationFetching: boolean
}
type UserStoreData = {
  currentUser: User | null
  authenticationErrorMessage: string | null
  currentUserMetrics: UserMetrics | null
  currentUserValidation: UserValidation | null
  userNotes: UserNote[]
  userTopReferenceStandards: ReferenceStandard[]
  userBottomReferenceStandards: ReferenceStandard[]
  userRecentRepeatedMeasures: RecentRepeatedMeasure[]
  loaded: UserStoreDataLoadingInfo
}

const createUserFromApiDataAndSetDefaultTimezone = (apiData: any): User => {
  if (apiData.timezone) {
    moment.tz.setDefault(apiData.timezone.descriptor)
  }

  const organizationStore = useOrganizationStore()
  organizationStore.currentOrganization = organizationFactory(apiData.current_organization)

  return {
    id: apiData.id,
    firstName: apiData.first_name,
    lastName: apiData.last_name,
    email: apiData.email,
    profileIcon: apiData.profile_icon,
    currentOrgUuid: apiData.current_org_uuid,
    canAccessAdminPanel: apiData.can_access_admin_panel,
    isBeingImpersonated: apiData.is_being_impersonated,
    userProfile: {
      userId: apiData.current_profile?.user_id,
      phone: apiData.current_profile?.phone,
      department_id: apiData.current_profile?.department_id,
      supervisor: apiData.current_profile?.supervisor
    },
    timezone: {
      id: apiData.timezone?.id,
      descriptor: apiData.timezone?.descriptor,
      label: apiData.timezone?.label,
      created_at: apiData.timezone?.created_at
    }
  }
}

export const useUserStore = defineStore('UserStore', {
  state: (): UserStoreData => ({
    currentUser: null,
    currentUserMetrics: null,
    currentUserValidation: null,
    authenticationErrorMessage: null,
    userTopReferenceStandards: [] as ReferenceStandard[],
    userBottomReferenceStandards: [] as ReferenceStandard[],
    userRecentRepeatedMeasures: [] as RecentRepeatedMeasure[],
    userNotes: [] as UserNote[],
    loaded: {
      user: false,
      userFetching: false,
      metrics: false,
      metricsFetching: false,
      notes: false,
      notesFetching: false,
      referenceStandards: false,
      referenceStandardsFetching: false,
      recentRepeatedMeasures: false,
      recentRepeatedMeasuresFetching: false,
      validation: false,
      validationFetching: false
    }
  }),

  actions: {
    async userTimezoneCheck(user: User) {
      const currentTimeZoneDescriptor: string = moment.tz.guess()
      const currentTimezoneAccepted = useLocalStorage('accepted-current-timezone', null)
      const utcOffset: string = moment.tz(currentTimeZoneDescriptor).format('Z')

      if (currentTimezoneAccepted.value !== currentTimeZoneDescriptor) {
        currentTimezoneAccepted.value = null
      }

      if (
        currentTimeZoneDescriptor !== user.timezone.descriptor &&
        currentTimezoneAccepted.value == null
      ) {
        //Toast!
        const toasterStore = useToasterStore()
        const timezoneStore = useTimezoneStore()
        let currentTimeZone: Timezone | null = null

        timezoneStore.fetchTimezone(currentTimeZoneDescriptor).finally(() => {
          currentTimeZone = timezoneStore.currentTimezone

          if (currentTimeZone != null) {
            const timezone: Timezone = currentTimeZone
            const toastPayload = {
              title: 'Timezone Change Detected',
              text: 'Update timezone to ' + currentTimeZone.label + ' (UTC' + utcOffset + ')?',
              buttons: [
                {
                  label: 'Yes',
                  closeOnClick: true,
                  isPrimary: true,
                  action: () => {
                    this.updateUserTimezone(timezone)
                    // reload or update page to see new times
                  }
                },
                {
                  label: 'No',
                  closeOnClick: true,
                  action: () => {
                    // Don't ask again for this session/time frame/ ?
                    currentTimezoneAccepted.value = currentTimeZone?.descriptor ?? ''
                  }
                }
              ]
            }
            toasterStore.info(toastPayload)
          }
        })
      }
    },
    async fetchUser() {
      const route = useRoute()
      this.loaded.user = false
      this.loaded.userFetching = true
      return axios
        .get('/api/current-user')
        .then((response) => {
          this.currentUser = createUserFromApiDataAndSetDefaultTimezone(response.data.data)
          this.loaded.user = true
          this.userTimezoneCheck(this.currentUser as User)
        })
        .catch((error) => {
          this.loaded.user = false
          if (error.response?.status === 401) {
            if (
              error.response?.data?.message &&
              error.response?.data?.message !== 'Unauthenticated'
            ) {
              this.authenticationErrorMessage = error.response?.data?.message
            }
            // Route to log in
            router.push('/login')
          }
        })
        .finally(() => {
          this.loaded.userFetching = false
        })
    },
    async fetchUserValidation() {
      this.loaded.validation = false
      this.loaded.validationFetching = true
      return axios.get('/api/current-user/validation').then((response) => {
        this.currentUserValidation = {
          hopsValidated: response.data.data.hops_validated,
          beerValidated: response.data.data.beer_validated
        }
      })
    },
    async fetchUserMetrics() {
      this.loaded.metrics = false
      this.loaded.metricsFetching = true
      return axios
        .get('/api/current-user/metrics')
        .then((response) => {
          this.currentUserMetrics = {
            leaderboardStats: response.data.data.leaderboard_stats.map((leaderboard: any) => {
              return {
                id: leaderboard.id,
                name: leaderboard.name,
                achievementLabel: leaderboard.label,
                currentUserAchievement: leaderboard.current_user,
                leaders: leaderboard.leaders?.map((leader: any) => {
                  return {
                    firstName: leader.first_name,
                    lastName: leader.last_name,
                    place: leader.place,
                    achievement: leader.achievement,
                    isCurrentUser: leader.is_current_user,
                    profileIcon: leader.profile_icon
                  }
                })
              }
            }),
            aromaSelectionTimeframes: response.data.data.aroma_selection_timeframes,
            aromaLabels: response.data.data.aroma_labels
          }
          this.loaded.metrics = true
        })
        .catch((error) => {
          if (error.response?.status !== 401) {
            console.error(error)
          }
        })
        .finally(() => {
          this.loaded.metricsFetching = false
        })
    },
    async fetchUserReferenceStandards() {
      this.loaded.referenceStandards = false
      this.loaded.referenceStandardsFetching = true
      return axios
        .get('/api/current-user/reference-standards')
        .then((response) => {
          this.userTopReferenceStandards = []
          this.userBottomReferenceStandards = []
          response.data.data.top.forEach((reference: any) => {
            this.userTopReferenceStandards.push({
              id: reference.id,
              name: reference.name,
              percentage: reference.percentage
            })
          })
          response.data.data.bottom.forEach((reference: any) => {
            this.userBottomReferenceStandards.push({
              id: reference.id,
              name: reference.name,
              percentage: reference.percentage
            })
          })
          this.loaded.referenceStandards = true
        })
        .catch((error) => {
          if (error.response?.status !== 401) {
            console.error(error)
          }
        })
        .finally(() => {
          this.loaded.referenceStandardsFetching = false
        })
    },
    async fetchUserRecentRepeatedMeasures() {
      this.loaded.recentRepeatedMeasures = false
      this.loaded.recentRepeatedMeasuresFetching = true
      return axios
        .get('/api/current-user/recent-repeated-measures')
        .then((response) => {
          this.userRecentRepeatedMeasures = []
          response.data.data[0].forEach((repeatedMeasure: any) => {
            this.userRecentRepeatedMeasures.push({
              display: repeatedMeasure.test.test_name,
              panelName: repeatedMeasure.test.panel.name,
              panelDate: repeatedMeasure.panel_session.start_time,
              score: repeatedMeasure.score
            })
          })
          this.loaded.recentRepeatedMeasures = true
        })
        .catch((error) => {
          if (error.response?.status !== 401) {
            console.error(error)
          }
        })
        .finally(() => {
          this.loaded.recentRepeatedMeasuresFetching = false
        })
    },
    async fetchUserNotes() {
      this.loaded.notes = false
      this.loaded.notesFetching = true
      return axios
        .get('/api/current-user/notes')
        .then((response) => {
          response.data.data.forEach((userNote: any) => {
            this.userNotes.push({
              id: userNote.id,
              userId: userNote.user_id,
              note: userNote.note,
              readAt: userNote.read_at,
              createdAt: userNote.created_at
            })
          })
          this.loaded.notes = true
        })
        .catch((error) => {
          if (error.response?.status !== 401) {
            console.error(error)
          }
        })
        .finally(() => {
          this.loaded.notesFetching = false
        })
    },
    async markUserNotesAsRead(notes: UserNote[]) {
      return axios.post('/api/current-user/notes', { notes: notes }).then((response) => {
        response.data.data.notes.forEach((userNote: any) => {
          this.userNotes.push({
            id: userNote.id,
            userId: userNote.user_id,
            note: userNote.note,
            readAt: userNote.read_at,
            createdAt: userNote.created_at
          })
        })
      })
    },
    async updateUser(user: User | null) {
      this.loaded.user = false
      this.loaded.userFetching = true
      return axios
        .post('/api/current-user', user)
        .then((response) => {
          this.currentUser = createUserFromApiDataAndSetDefaultTimezone(response.data.data)
          this.loaded.user = true
        })
        .finally(() => {
          this.loaded.userFetching = false
        })
    },
    async updateUserTimezone(timezone: Timezone) {
      this.loaded.user = false
      this.loaded.userFetching = true
      return axios
        .post('/api/current-user/timezone', {
          timezone_id: timezone.id
        })
        .then((response) => {
          this.currentUser = createUserFromApiDataAndSetDefaultTimezone(response.data.data)
          this.loaded.user = true
          router.go(0)
        })
        .finally(() => {
          this.loaded.userFetching = false
        })
    }
  },
  getters: {
    currentUserName(state) {
      if (state.currentUser === null) return 'Guest'
      return state.currentUser.firstName + ' ' + state.currentUser.lastName.charAt(0) + '.'
    },
    currentOrgUuid(state): string {
      if (state.currentUser === null) return ''
      return state.currentUser.currentOrgUuid
    },
    currentTimezone(state): string {
      if (state.currentUser === null) return moment.tz.guess()
      return state.currentUser.timezone.descriptor
    },
    isCurrentAdmin(state): boolean {
      if (state.currentUser === null) return false
      return state.currentUser.canAccessAdminPanel
    }
  }
})
