<template>
  <div>
    <a class="session__filters__link" href="#" @click.prevent="changeSort()">
      <slot></slot>
      <span class="session__filters__link__indicator">
        <YIcon v-if="downIconOn" icon="caret-down" />
        <YIcon v-else-if="upIconOn" icon="caret-up" />
      </span>
    </a>
  </div>
</template>
<script setup lang="ts">
import { YIcon } from '@yakimachief/ych-ts-component-library'
import { computed } from 'vue'
import { sessionsFilterSettings } from '@/components/UpcomingSessions/upcomingSessionsComposables'
import type { PanelSessionsSortField } from '@/components/UpcomingSessions/upcomingSessionsComposables'

export interface Props {
  column: PanelSessionsSortField
}

const props = defineProps<Props>()

const downIconOn = computed(() => {
  return (
    props.column === sessionsFilterSettings.value.currentSortField &&
    sessionsFilterSettings.value.currentSortDirection === 'asc'
  )
})

const upIconOn = computed(() => {
  return (
    props.column === sessionsFilterSettings.value.currentSortField &&
    sessionsFilterSettings.value.currentSortDirection === 'desc'
  )
})

function changeSort() {
  if (props.column === sessionsFilterSettings.value.currentSortField) {
    if (sessionsFilterSettings.value.currentSortDirection === 'asc') {
      sessionsFilterSettings.value.currentSortDirection = 'desc'
    } else {
      sessionsFilterSettings.value.currentSortDirection = 'asc'
    }
  } else {
    sessionsFilterSettings.value.currentSortField = props.column
    sessionsFilterSettings.value.currentSortDirection = 'asc'
  }
}
</script>
<style lang="scss">
.session__filters__link {
  white-space: nowrap;
  display: flex;
  gap: 0.25rem;
  align-items: center;

  &__indicator {
    display: inline-block;
    text-align: right;
  }
}
</style>
