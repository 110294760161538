<template>
  <div class="session-item flex flex-row justify-between items-center w-full flex-wrap gap-4 p-3">
    <div class="session-item__left-content gap-2 flex items-start flex-col">
      <div class="flex top-row items-center flex-wrap">
        <h4 class="bullet-separated">
          <y-icon
            :icon="downloadingIcalFile ? 'calendar-check' : 'calendar'"
            v-if="props.panelSession.isAdded"
            @click="downloadIcalFile"
            class="mr-1"
          ></y-icon>
          {{ date }}
        </h4>
        <h4 class="bullet-separated">{{ time }}</h4>
        <h4 class="bullet-separated location flex gap-1.5 content-center">
          {{ location }}<i class="fa-solid fa-location-dot" />
        </h4>
      </div>
      <div class="flex gap-1">
        <small v-if="props.panelSession.maxPanelists"
          >{{ openSlots }}/{{ props.panelSession.maxPanelists }} slots available</small
        >
        <small v-if="props.panelSession.adminLink">
          <a :href="props.panelSession.adminLink as string">Manage Session</a>
        </small>
      </div>
    </div>
    <div class="session-item__right-content flex justify-end items-center">
      <div class="button-container grid col-span-1 content-center justify-end">
        <PanelSessionButton :panel-session="props.panelSession" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'
import moment from 'moment'
import type { PanelSession } from '@/types/PanelSession'
import PanelSessionButton from '@/components/UpcomingSessions/PanelSessionButton.vue'
import { YIcon } from '@yakimachief/ych-ts-component-library'

/**
 * Properties
 */
const props = defineProps<{
  panelSession: PanelSession
}>()

const downloadingIcalFile = ref(false)

/**
 * Computed Values
 */

/* @todo: plumb registration availability variables */
const registeredNum = 16
const totalSlots = 24
const openSlots = computed(() => {
  if (props.panelSession.maxPanelists === null) return false
  return props.panelSession.maxPanelists - props.panelSession.panelistCount
})

const date = computed(() => {
  return moment(props.panelSession.startTime).format('MMM D, YYYY')
})
const time = computed(() => {
  return moment(props.panelSession.startTime).format('h:mm a')
})

const location = computed(() => {
  return props.panelSession.location?.name
})

function downloadIcalFile() {
  window.location.href = props.panelSession.icalEventUrl
  downloadingIcalFile.value = true

  setTimeout(function () {
    downloadingIcalFile.value = false
  }, 1500)
}

/**
 * Hanlder functions
 */
</script>
<style scoped lang="scss">
.session-item {
  &:nth-child(even) {
    background: var(--ych-grey-100);
  }

  .bullet-separated {
    display: inline-flex;

    &:after {
      content: '\2022';
      font-size: var(--y-font-size-md);
      color: var(--secondary-brand-color);
      padding-right: 0.25rem;
      padding-left: 0.25rem;
    }

    &:last-of-type:after {
      content: '';
    }
  }

  .location i.fa-location-dot {
    margin-top: 2px;
  }
}
</style>
