<template>
  <div class="header-wrapper flex justify-center">
    <header class="px-4 w-full sm:px-12 items-center justify-between flex relative">
      <div class="flex items-center flex-shrink-0 pr-2 relative">
        <RouterLink to="/" class="flex gap-3 no-underline items-center">
          <img alt="YCH logo" class="logo" src="@/assets/images/ych-icon.svg" />
          <h1 class="pr-4">SENSORY</h1>
        </RouterLink>
        <OrganizationSwitcher :disable-switcher="disableOrgSwitcher" />
      </div>

      <div class="block">
        <div v-if="userStore.currentUser">
          <div
            v-on-click-outside="closeProfileMenu"
            class="current-user"
            @click="showProfileMenu = !showProfileMenu"
          >
            <div class="current-user__name">
              {{ userStore.currentUser?.firstName }}<br />
              {{ userStore.currentUser?.lastName }}
            </div>
            <user-profile-icon :user="userStore.currentUser"></user-profile-icon>
          </div>
          <profile-menu v-if="showProfileMenu" />
        </div>
      </div>
    </header>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { useUserStore } from '@/stores/UserStore'
import { RouterLink, useRoute, useRouter } from 'vue-router'
import ProfileMenu from './ProfileMenu.vue'
import { vOnClickOutside } from '@vueuse/components'
import { OrganizationSwitcher } from '@/components/OrganizationSwitcher'
import UserProfileIcon from '@/components/UserProfileIcon.vue'

const disableOrgSwitcher = ref(false)
const router = useRouter()
onMounted(async () => {
  await router.isReady()
  disableOrgSwitcher.value = router.currentRoute.value.name == 'test'
})
const showProfileMenu = ref(false)

const userStore = useUserStore()

function closeProfileMenu(pointerEvent: PointerEvent) {
  if (showProfileMenu.value == true) {
    showProfileMenu.value = false
  }
}
</script>

<style lang="scss" scoped>
.header-wrapper {
  background-color: var(--primary-brand-color);
  width: 100%;
  position: relative;
  z-index: 999;
}

header {
  background-color: var(--primary-brand-color);
  max-width: 1920px;
  height: 3.75rem;

  img.logo {
    height: 2.5rem;
    width: auto;
    margin-top: 2px;
  }

  h1 {
    color: var(--primary-accent-color);
  }

  .login-button {
    color: var(--primary-accent-color);
    text-decoration: none;
  }

  .current-user {
    display: flex;
    align-items: center;
    grid-gap: 0.75rem;
    cursor: pointer;

    &__name {
      text-align: right;
      color: var(--primary-accent-color);
      line-height: 1.25rem;
    }
  }
}
</style>
