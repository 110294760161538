<template>
  <li class="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50">
    <div class="grid grid-cols-4 place-items-center w-full gap-2">
      <p
        class="grid col-span-2 font-semibold text-gray-900 user-schedule__selected-day__panel-session-title w-full text-left"
      >
        {{ panelSession.panelName }}
      </p>
      <time
        :datetime="moment(panelSession.startTime).format(moment.HTML5_FMT.DATETIME_LOCAL)"
        class="flex items-center user-schedule__selected-day__panel-time w-full text-left"
      >
        <i class="fa-regular fa-clock" aria-hidden="true"></i>
        {{ moment(panelSession.startTime).format('h:mm a') }}
      </time>
      <div class="w-full flex justify-end">
        <YButton
          class="user-schedule__selected-day__view-button"
          label="View"
          size="sm"
          @click="dialogOpen = true"
          :mobile-fill="false"
        />
      </div>
    </div>
    <UserSchedulePanelSessionDialog :panel-session="panelSession" v-model:open="dialogOpen" />
  </li>
</template>
<script setup lang="ts">
import { YButton } from '@yakimachief/ych-ts-component-library'
import moment from 'moment'
import UserSchedulePanelSessionDialog from '@/components/UserSchedule/UserSchedulePanelSessionDialog.vue'
import { computed, ref } from 'vue'
import { getPanelTypeLabel } from '@/components/UpcomingSessions/upcomingSessionsComposables'
import type { PanelSession } from '@/types/PanelSession'

const props = defineProps<{
  panelSession: PanelSession
}>()

const dialogOpen = ref(false)
</script>

<style lang="scss" scoped>
.user-schedule__selected-day {
  &__panel-session-title {
    text-transform: capitalize;
  }

  &__panel-time {
    gap: 0.2rem;
    white-space: nowrap;
  }

  &__view-button {
    width: fit-content !important;
  }
}
</style>
