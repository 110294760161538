import { defineStore } from 'pinia'
import type { ToastStatus } from '@/types/ToastStatus'
import type { ToastButton } from '@/types/ToastButton'
import type { Toast } from '@/types/Toast'

const defaultTimeout = 0

type ToastPayload = {
  timeout?: number
  title: string
  text: string
  buttons?: Array<ToastButton>
}

const createToast = (
  title: string,
  text: string,
  status: ToastStatus,
  buttons: Array<ToastButton> | null = null
): Toast => ({
  title,
  text,
  status,
  buttons,
  id: Math.random() * 1000
})
const useToasterStore = defineStore('toaster-store', {
  state: (): { toasts: Toast[] } => ({
    toasts: []
  }),
  actions: {
    updateState(payload: ToastPayload, status: ToastStatus) {
      const { title, text, timeout, buttons } = payload

      const existingToastId: number = this.toasts.findIndex((toast: Toast) => {
        return (
          toast.title === title &&
          toast.text === text &&
          JSON.stringify(toast.buttons) === JSON.stringify(buttons)
        )
      })

      if (existingToastId < 0) {
        const toast: Toast = createToast(title, text, status, buttons)

        this.toasts.push(toast)
        const toastTimeout: number = timeout ? timeout * 1000 : defaultTimeout
        if (toastTimeout > 0) {
          setTimeout(() => {
            this.removeToast(toast.id)
          }, toastTimeout)
        }
      }
    },
    removeToast(id: number) {
      this.toasts = this.toasts.filter((t) => t.id !== id)
    },
    success(payload: ToastPayload) {
      this.updateState(payload, 'success')
    },
    info(payload: ToastPayload) {
      this.updateState(payload, 'info')
    },

    warning(payload: ToastPayload) {
      this.updateState(payload, 'warning')
    },

    error(payload: ToastPayload) {
      this.updateState(payload, 'error')
    }
  }
})

const successToast = (title: string, text: string) => {
  const toasterStore = useToasterStore()
  toasterStore.success({
    title: title,
    text: text,
    timeout: 5
  })
}
const errorToast = (title: string, text: string) => {
  const toasterStore = useToasterStore()
  toasterStore.error({
    title: title,
    text: text,
    timeout: 5
  })
}
const quickToast = (title: string, text: string, status: ToastStatus) => {
  const toasterStore = useToasterStore()
  toasterStore.updateState({ title: title, text: text, timeout: 5 }, status)
}

export default useToasterStore
export { useToasterStore, successToast, errorToast, quickToast }
