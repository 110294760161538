<script setup lang="ts">
import { Radar } from 'vue-chartjs'
import { Chart, registerables } from 'chart.js'
import type { ChartData, ChartOptions } from 'chart.js'
import type { AromaSelectionTimeframe } from '@/types/AromaSelectionTimeframe'
import type { RadarDataset } from '@/types/RadarDataset'
import { computed, ref } from 'vue'
import type { UserMetrics } from '@/types/UserMetrics'
import { YDropdown } from '@yakimachief/ych-ts-component-library'
import CardSpinner from '@/components/CardSpinner.vue'
import { useUserStore } from '@/stores/UserStore'

Chart.register(...registerables)

export interface Props {
  metrics: UserMetrics | null
  size: string | number | undefined
  isH3: boolean
  displayHeadingInline: boolean
}

const props = withDefaults(defineProps<Props>(), {
  metrics: null,
  size: '17rem',
  isH3: true,
  displayHeadingInline: true
})

const userStore = useUserStore()
const selectedAromaTimeline = ref({ id: 1 })
// LexiconItem Selection Variables
const aromaSelectionTimeframeDropdown = computed(() => {
  let options
  if (props.metrics) {
    options = props.metrics.aromaSelectionTimeframes.map((timeframe: AromaSelectionTimeframe) => {
      return {
        id: timeframe.id,
        name: timeframe.name
      }
    })
    selectedAromaTimeline.value = options[0]
  }
  return options
})

function getChartData(): ChartData<'radar'> {
  let datasets: any[] = []
  let aromaLabels: any[] = []
  if (
    props.metrics &&
    props.metrics?.aromaSelectionTimeframes &&
    props.metrics?.aromaSelectionTimeframes.length > 0 &&
    selectedAromaTimeline.value
  ) {
    let index: number = props.metrics?.aromaSelectionTimeframes.findIndex(
      (timeframe: AromaSelectionTimeframe) => timeframe.id == selectedAromaTimeline.value.id
    )
    let selectionTimeframeObject: AromaSelectionTimeframe =
      props.metrics?.aromaSelectionTimeframes[index]

    datasets = selectionTimeframeObject.datasets.map((dataset: RadarDataset) => {
      const data = dataset.data.map((sensoryValue) => {
        return sensoryValue / 100
      })

      const firstLetter = dataset.id.charAt(0).toUpperCase()
      const remainingLetters = dataset.id.substring(1)

      return {
        data: data,
        label: firstLetter + remainingLetters,
        backgroundColor: dataset.backgroundColor,
        borderColor: dataset.borderColor,
        fill: dataset.fill
      }
    })
    aromaLabels = props.metrics?.aromaLabels
  }
  return {
    labels: aromaLabels,
    datasets: datasets
  }
}

function getChartOptions(): ChartOptions<'radar'> {
  return {
    responsive: true,
    maintainAspectRatio: true,
    interaction: {
      intersect: false,
      mode: 'index',
      axis: 'xy'
    },
    scales: {
      r: {
        // display: false,
        angleLines: {
          // display: false
          color: '#CECFD6'
        },
        ticks: {
          display: true,
          color: '#002B49',
          stepSize: 0.2,
          font: {
            size: 10.24,
            weight: 'bold'
          },
          format: {
            style: 'percent'
          },
          callback: function (ticks: string | number) {
            return (parseFloat(<string>ticks) * 100).toFixed() + '%'
          }
        },
        min: 0,
        max: 1,
        grid: {
          circular: true,
          color: '#CECFD6'
        },
        pointLabels: {
          color: '#002B49',
          font: {
            weight: 'bold',
            size: 10.24
          },
          callback: function (value: string) {
            return value.toUpperCase()
          },
          display: false
        }
      }
    },
    elements: {
      point: {
        pointStyle: false
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  }
}
</script>

<template>
  <div class="relative divide-color">
    <div class="aroma-selection grid pt-3 gap-4" v-if="metrics" :style="{ '--size': size }">
      <div
        class="aroma-selection__top-row"
        :class="{
          'inline-flex': displayHeadingInline,
          'justify-between': displayHeadingInline,
          'items-center': displayHeadingInline,
          'display-block': !displayHeadingInline
        }"
      >
        <h3 v-if="isH3">
          Aroma Selection<br v-if="displayHeadingInline" />
          <span class="y-color-grey"> vs</span>
          <span class="y-color-primary"> Group Avg</span>
        </h3>
        <h2 v-if="!isH3">
          Aroma Selection<br v-if="displayHeadingInline" />
          <span class="y-color-grey"> vs</span>
          <span class="y-color-primary"> Group Avg</span>
        </h2>
        <y-dropdown
          v-if="aromaSelectionTimeframeDropdown"
          v-model="selectedAromaTimeline"
          :options="aromaSelectionTimeframeDropdown"
          placeholder="Select time frame..."
          defaultOptionValue="1"
        />
      </div>
      <div class="aroma-selection__graph-container flex justify-center">
        <Radar
          class="aroma-selection__graph-container__spider"
          id="aroma-chart"
          :data="getChartData()"
          :options="getChartOptions()"
        ></Radar>
        <div class="aroma-selection__graph-container__labels">
          <img
            src="/../public/images/spider-graph-labels.svg"
            class="aroma-selection__graph-container__labels__img"
            alt="Labels for accompanying chart, for a more accessible version, please visit the Table View"
          />
        </div>
      </div>
    </div>
    <CardSpinner v-if="userStore.loaded.metricsFetching" />
  </div>
</template>

<style lang="scss">
.y-color-primary {
  color: var(--primary-brand-color);
}
.divide-color {
  border-color: var(--ych-grey-200);
  border-style: solid;
}
.display-block {
  h2,
  h3 {
    padding-bottom: 20px;
  }
  .y-dropdown {
    margin: 0 auto;
    width: 80% !important;
  }
}
</style>

<style scoped lang="scss">
.aroma-selection {
  &__graph-container {
    max-width: 100%;
    width: 100%;
    height: var(--size);
    padding: 0.75rem;
    margin: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    aspect-ratio: 1/1;

    &__spider {
      width: var(--size);
      height: var(--size);
      background: transparent;
      display: block;
      padding: 0;
      margin: 0;
      aspect-ratio: 1/1;

      p {
        display: none;
      }
    }

    &__labels {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
      z-index: 1;

      &__img {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        aspect-ratio: 1/1;
      }
    }
  }
}
</style>
