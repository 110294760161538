<script setup lang="ts">
import { onMounted, ref } from 'vue'
import {
  Engine,
  Render,
  Bodies,
  Body,
  Composite,
  Composites,
  Mouse,
  Runner,
  MouseConstraint,
  Common,
  Vector
} from 'matter-js'
import { useUserStore } from '@/stores/UserStore'
import { YIcon, YButton } from '@yakimachief/ych-ts-component-library'

const userStore = useUserStore()

onMounted(() => {
  // create engine
  let engine = Engine.create(),
    world = engine.world

  const element: HTMLElement | undefined = document.getElementById('y-matter') as HTMLElement

  const scale = 1
  // create renderer
  let render = Render.create({
    element: element,
    engine: engine,
    options: {
      background: '#002b49',
      wireframeBackground: '#fff',
      width: element.clientWidth,
      height: element.clientHeight,
      showAngleIndicator: false,
      wireframes: false,
      pixelRatio: window.devicePixelRatio
    }
  })

  Render.run(render)

  // create runner
  let runner = Runner.create()
  Runner.run(runner, engine)

  world.bodies = []

  let floor = Bodies.rectangle(0, element.clientHeight, element.clientWidth * 2, 2, {
    isStatic: true,
    render: {
      visible: true
    }
  })

  Composite.add(world, floor)

  let stack = Composites.stack(
    20, // xx Number
    20, // yy Number
    12, // Colums
    6, // Rows
    element.clientWidth / 12, // Column Gap: Screen Width/Number of Columns creates equal spacing
    60, // Row Gap
    function (x: number, y: number) {
      // Randomize position
      const direction = (Common.random() * 100) % 2 ? -1 : 1
      const distance = Common.random() * 100
      let vertices = [
        [
          { x: -20, y: 0 },
          { x: -16, y: -22 },
          { x: -10, y: -37 },
          { x: 0, y: -43 },
          { x: 10, y: -37 },
          { x: 16, y: -22 },
          { x: 20, y: 0 },
          { x: 14, y: 22 },
          { x: 0, y: 43 },
          { x: -14, y: 22 }
        ]
      ]

      let cones = Bodies.fromVertices(x + direction * distance, y, vertices, {
        density: 0.001,
        frictionAir: 0.03,
        restitution: 0.2,
        friction: 0.05,
        collisionFilter: { category: 0b10 },
        render: {
          sprite: {
            texture: '/images/hop-cone.png',
            xScale: scale,
            yScale: scale
          }
        }
      })
      Body.setAngle(cones, Common.random() * 360)
      Body.setAngularVelocity(cones, 0)

      return cones
    }
  )

  window.addEventListener('resize', () => {
    render.bounds.max.x = element.clientWidth
    render.bounds.max.y = element.clientHeight
    render.options.width = element.clientWidth
    render.options.height = element.clientHeight
    render.canvas.width = element.clientWidth
    render.canvas.height = element.clientHeight
    Composite.remove(world, floor)
    floor = Bodies.rectangle(0, element.clientHeight, element.clientWidth * 2, 2, {
      isStatic: true,
      render: {
        visible: true
      }
    })
    Composite.add(world, floor)
    Render.setPixelRatio(render, window.devicePixelRatio)
  })

  Composite.add(world, stack)

  // add mouse control
  let mouse = Mouse.create(render.canvas),
    mouseConstraint = MouseConstraint.create(engine, {
      mouse: mouse,
      collisionFilter: { mask: 0b10 },
      constraint: {
        stiffness: 0.2,
        render: {
          visible: false
        }
      }
    })

  Composite.add(world, mouseConstraint)

  // keep the mouse in sync with rendering
  render.mouse = mouse

  // fit the render viewport to the scene
  Render.lookAt(render, {
    min: { x: 0, y: 0 },
    max: { x: element.clientWidth, y: element.clientHeight }
  })
})
</script>

<template>
  <div id="y-matter"></div>
  <div class="login">
    <h2 class="h1 login__welcome">Welcome<br />to Sensory</h2>
    <div class="card flex flex-col gap-4">
      <h3>Sign in to Your Account</h3>
      <a href="/kinde-login">
        <y-button
          :show-icon="false"
          label="Sign In"
          background-color="secondary"
          class="regular-sign-in pointer-events-auto"
          :mobile-fill="false"
          size="lg"
        />
      </a>
      <a href="/ms-redirect" class="login__button pointer-events-auto">
        <img src="@/assets/images/ych-icon.svg" width="44" />Staff Sign In
      </a>
    </div>
    <transition name="fade-up">
      <div v-if="userStore.authenticationErrorMessage" class="login__error-message">
        <div class="login__error-message__header">
          <y-icon class="login__error-message__header__icon" icon="circle-exclamation-solid" />
          <h2 class="h4 login__error-message__header__title">Login Error</h2>
        </div>
        <p class="login__error-message__text">{{ userStore.authenticationErrorMessage }}</p>
      </div>
    </transition>
  </div>
</template>
<style></style>
<style scope lang="scss">
#y-matter {
  height: calc(100vh + 800px);
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 3.75rem);
  width: 100%;
  gap: 4rem;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  z-index: 2;

  &__welcome.h1 {
    line-height: 4.5rem;
    color: var(--primary-accent-color);
    text-align: center;
    font-size: 4.5rem;
    text-transform: uppercase;
    font-family: var(--y-font-family-serif);
  }

  &__error-message {
    background-color: var(--ych-white);
    border-radius: 0.5rem;
    box-shadow: var(--y-shadow-grey-md);
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    width: 26rem;
    max-width: 90%;
    pointer-events: all;

    &__header {
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.25rem;
      width: 100%;

      &__icon {
        font-size: 1rem;
        color: var(--ych-red);
      }

      &__title {
        color: var(--ych-blue);
      }
    }

    &__text {
      color: var(--ych-blue);
    }
  }

  &__button {
    padding: 0.75rem 0.75rem;
    background: var(--primary-brand-color);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    color: var(--primary-accent-color);
    text-decoration: none;
    border-radius: var(--y-radius-lg);
    min-width: 10.5rem;
    height: 3.5rem;

    &:hover {
      box-shadow: var(--y-shadow-red-md);
      cursor: pointer;
    }

    &:active {
      background-color: var(--ych-red-dark);
      box-shadow: none;
    }
  }
}

.fade-up-enter-active,
.fade-up-leave-active {
  transition:
    transform 0.4s ease-in,
    opacity 0.4s ease-in;
}

.fade-up-enter-from,
.fade-up-leave-to {
  transform: translateY(100px);
  opacity: 0;
}

@media (min-width: 768px) {
  .login__welcome.h1 {
    line-height: 8rem;
    font-size: 8rem;
  }
}

@media (min-width: 1280px) {
  .login__welcome.h1 {
    line-height: 10rem;
    font-size: 10rem;
  }
}
</style>
