<template>
  <UpcomingSessionsFilters :show-location="false" />
  <div class="panel__list" v-if="panels.length > 0">
    <GroupByPanelItem v-for="panel in panels" :panel="panel" :key="'panel-id-' + panel.id" />
  </div>
  <div v-else>
    <p class="alert alert-info">There are currently no available sessions</p>
  </div>
  <div class="inner-shadow" v-if="panels.length > 3"></div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import GroupByPanelItem from '@/components/UpcomingSessions/GroupByPanelItem.vue'
import { usePanelSessionStore } from '@/stores/PanelSessionStore'
import UpcomingSessionsFilters from '@/components/UpcomingSessions/UpcomingSessionsFilters.vue'
import type {
  PanelSessionsFilterType,
  PanelSessionsSortDirection,
  PanelSessionsSortField
} from '@/components/UpcomingSessions/upcomingSessionsComposables'
import { sessionsFilterSettings } from '@/components/UpcomingSessions/upcomingSessionsComposables'

const panelSessionStore = usePanelSessionStore()
const panels = computed(() => {
  let panels = panelSessionStore.panels

  // Default sort date asc, provides a secondary sort for other fields
  panels = panels.sort(
    (panelA, panelB) =>
      (panelA.panelSessions?.at(0)?.startTime.getTime() ?? 0) -
      (panelB.panelSessions?.at(0)?.startTime.getTime() ?? 0)
  )

  const field: PanelSessionsSortField = sessionsFilterSettings.value.currentSortField
  const direction: PanelSessionsSortDirection = sessionsFilterSettings.value.currentSortDirection
  const filter: PanelSessionsFilterType[] = sessionsFilterSettings.value.filterType
  panels = panels.filter((panel) => {
    if (filter.includes('beer')) {
      if (panel.panelSessions?.at(0)?.types.includes('beer')) {
        return false
      }
    }
    if (filter.includes('hops')) {
      if (panel.panelSessions?.at(0)?.types.includes('hops')) {
        return false
      }
    }
    if (filter.includes('other')) {
      if (panel.panelSessions?.at(0)?.types.length === 0) return false
      const otherTypes = panel.panelSessions?.at(0)?.types.filter((type) => {
        return !(type === 'hops' || type === 'beer')
      })
      if (otherTypes?.length ?? 0 > 0) {
        return false
      }
    }
    return true
  })
  panels = panels.filter((panel) => {
    return panel.panelSessions
      ?.at(0)
      ?.panelName.toLowerCase()
      .includes(sessionsFilterSettings.value.search.toLowerCase())
  })

  // Default sort date asc, provides a secondary sort for other fields

  if (field === 'date-time') {
    if (direction === 'desc') {
      panels.sort(
        (b, a) =>
          (a.panelSessions?.at(0)?.startTime.getTime() ?? 0) -
          (b.panelSessions?.at(0)?.startTime.getTime() ?? 0)
      )
    }
  } else if (field === 'session-type') {
    if (direction === 'asc') {
      panels.sort((a, b) => {
        if (
          a.panelSessions?.at(0)?.types.length === 1 &&
          b.panelSessions?.at(0)?.types.length === 1
        ) {
          return (
            a.panelSessions
              ?.at(0)
              ?.types[0].localeCompare(b.panelSessions?.at(0)?.types[0] ?? '') ?? 0
          )
        }
        if (
          a.panelSessions?.at(0)?.types.length === 1 &&
          b.panelSessions?.at(0)?.types.length !== 1
        ) {
          return -1
        }
        if (
          a.panelSessions?.at(0)?.types.length !== 1 &&
          b.panelSessions?.at(0)?.types.length === 1
        ) {
          return 1
        }
        return 0
      })
    } else {
      panels.sort((b, a) => {
        if (
          a.panelSessions?.at(0)?.types.length === 1 &&
          b.panelSessions?.at(0)?.types.length === 1
        ) {
          return (
            a.panelSessions
              ?.at(0)
              ?.types[0].localeCompare(b.panelSessions?.at(0)?.types[0] ?? '') ?? 0
          )
        }
        if (
          a.panelSessions?.at(0)?.types.length === 1 &&
          b.panelSessions?.at(0)?.types.length !== 1
        ) {
          return -1
        }
        if (
          a.panelSessions?.at(0)?.types.length !== 1 &&
          b.panelSessions?.at(0)?.types.length === 1
        ) {
          return 1
        }
        return 0
      })
    }
  }

  return panels
})
</script>
<style scoped lang="scss">
.panel__list {
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 0.75rem;
  min-height: 88px;
  height: 100%;
  max-height: 25rem;
  width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  scrollbar-color: #70758a transparent;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  &::-webkit-scrollbar {
    width: 0.75rem;
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
    border: none;
    width: 0.75rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--ych-grey-400);
    border-radius: 0.5rem;
    border: 2px solid transparent;
    background-clip: content-box;
  }
}

.inner-shadow {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 0.5rem;
  pointer-events: none;
  z-index: 10;
  background: -webkit-linear-gradient(90deg, rgba(22, 22, 22, 0.25), transparent);
  background: linear-gradient(0deg, rgba(22, 22, 22, 0.25), transparent);
}
</style>
