<script setup lang="ts">
import { useUserStore } from '@/stores/UserStore'
import { computed } from 'vue'

const userStore = useUserStore()

const managePanelURL = computed(() => {
  return '/admin/' + userStore.currentOrgUuid + '/panels'
})
const createPanelURL = computed(() => {
  return '/admin/' + userStore.currentOrgUuid + '/panels/create'
})
const manageSessionsURL = computed(() => {
  return '/admin/' + userStore.currentOrgUuid + '/panel-sessions'
})
const beerSamplesURL = computed(() => {
  return '/admin/' + userStore.currentOrgUuid + '/beer-samples'
})
const createBeerSampleURL = computed(() => {
  return '/admin/' + userStore.currentOrgUuid + '/beer-samples/create'
})
const hopsSamplesURL = computed(() => {
  return '/admin/' + userStore.currentOrgUuid + '/hop-samples'
})
const createHopsSampleURL = computed(() => {
  return '/admin/' + userStore.currentOrgUuid + '/hop-samples/create'
})
const otherSamplesURL = computed(() => {
  return '/admin/' + userStore.currentOrgUuid + '/other-samples'
})
const createOtherSampleURL = computed(() => {
  return '/admin/' + userStore.currentOrgUuid + '/other-samples/create'
})
const manageCATA = computed(() => {
  return '/admin/' + userStore.currentOrgUuid + '/cata-tests'
})
const managePreference = computed(() => {
  return '/admin/' + userStore.currentOrgUuid + '/preference-tests'
})
const manageQDA = computed(() => {
  return '/admin/' + userStore.currentOrgUuid + '/qda-tests'
})
const manageSpikeScaling = computed(() => {
  return '/admin/' + userStore.currentOrgUuid + '/spike-scaling-tests'
})
const manageStandards = computed(() => {
  return '/admin/' + userStore.currentOrgUuid + '/standards-tests'
})
const manageTetrad = computed(() => {
  return '/admin/' + userStore.currentOrgUuid + '/tetrad-tests'
})
const manageTTP = computed(() => {
  return '/admin/' + userStore.currentOrgUuid + '/true-to-type-tests'
})
const manageUsersURL = computed(() => {
  return '/admin/' + userStore.currentOrgUuid + '/users'
})
const createUserURL = computed(() => {
  return '/admin/' + userStore.currentOrgUuid + '/users/create'
})
</script>

<template>
  <h2 class="pb-4">Admin Quick Links</h2>
  <ul v-if="userStore?.currentUser" class="admin-link-list">
    <li>
      <a :href="managePanelURL">Manage Panels</a>
      <ul class="dash-list">
        <li>
          <a :href="createPanelURL">Create Panel</a>
        </li>
        <li>
          <a :href="manageSessionsURL">Manage Sessions</a>
        </li>
      </ul>
    </li>
    <li>
      <a :href="beerSamplesURL">Manage Beer Samples</a>
      <ul class="dash-list">
        <li>
          <a :href="createBeerSampleURL">Create Beer Sample</a>
        </li>
      </ul>
    </li>
    <li>
      <a :href="hopsSamplesURL">Manage Hops Samples</a>
      <ul class="dash-list">
        <li>
          <a :href="createHopsSampleURL">Create Hops Sample</a>
        </li>
      </ul>
    </li>
    <li>
      <a :href="otherSamplesURL">Manage Other Samples</a>
      <ul class="dash-list">
        <li>
          <a :href="createOtherSampleURL">Create Other Sample</a>
        </li>
      </ul>
    </li>

    <li class="font-medium">
      Manage Tests
      <ul class="dash-list">
        <li>
          <a :href="manageCATA">CATA Tests</a>
        </li>
        <li>
          <a :href="managePreference">Preference Tests</a>
        </li>
        <li>
          <a :href="manageQDA">QDA Tests</a>
        </li>
        <li>
          <a :href="manageSpikeScaling">Spike Scaling Tests</a>
        </li>
        <li>
          <a :href="manageStandards">Standards Tests</a>
        </li>
        <li>
          <a :href="manageTetrad">Tetrad Tests</a>
        </li>
        <li>
          <a :href="manageTTP">True-to-Type Tests</a>
        </li>
      </ul>
    </li>
    <li>
      <a :href="manageUsersURL">Manage Users</a>
      <ul class="dash-list">
        <li>
          <a :href="createUserURL">Create User</a>
        </li>
      </ul>
    </li>
  </ul>
</template>
<style scoped lang="scss">
ul.admin-link-list {
  -moz-column-count: 1;
  -webkit-column-count: 1;
  column-count: 1;
  @media (min-width: 640px) {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    -moz-column-gap: 1rem;
    -webkit-column-gap: 1rem;
    column-gap: 1rem;
  }
  @media (min-width: 1280px) {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
    -moz-column-gap: 0.5rem;
    -webkit-column-gap: 0.5rem;
    column-gap: 0.5rem;
  }
  li {
    white-space: nowrap;
    page-break-inside: avoid;
    break-inside: avoid-column;
  }
}
ul.dash-list {
  list-style-type: none;
  padding-left: 1.5em;

  li {
    text-indent: -1em;
    &::before {
      content: '\2013';
      padding-right: 0.5em;
      font-family: var(--y-font-family-sans-serif);
      color: var(--ych-grey);
      font-weight: var(--y-font-weight-medium);
    }
  }
}
</style>
