<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="setIsOpen(false)">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 panel-session-modal__background" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="panel-session-modal relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
            >
              <DialogTitle>
                <div class="panel-session-modal__heading flex justify-between">
                  <h2>{{ panelSession.panelName }}</h2>
                  <button @click="setIsOpen(false)">
                    <i class="fa-duotone fa-xmark-large"></i>
                    <span class="sr-only">Close</span>
                  </button>
                </div>
              </DialogTitle>

              <div class="panel-session-modal__content">
                <div class="flex justify-between">
                  <div class="panel-session-modal__datetime">
                    <a :href="panelSession.icalEventUrl"><y-icon icon="calendar"></y-icon></a>
                    {{ moment(panelSession.startTime).format('MMMM DD, YYYY') }}
                    &bull;
                    {{ moment(panelSession.startTime).format('h:mm a') }}
                  </div>
                  <div class="panel-session-modal__panel-session-id">
                    (ID {{ panelSession.id }})
                  </div>
                </div>

                <div class="panel-session-modal__location">
                  <span class="panel-session-modal__location__name font-medium">{{
                    panelSession.location.name
                  }}</span>
                  <i class="fa-solid fa-location-dot fa-lg"></i>
                </div>
                <div
                  v-if="props.panelSession.types.length !== 0"
                  class="panel-session-modal__sample-icons flex gap-2 items-center"
                >
                  <div class="font-medium">Samples:</div>
                  <div class="flex gap-1 items-center">
                    <y-icon
                      icon="beer-mug"
                      v-if="hasBeer"
                      font-size="1rem"
                      title="This session includes beer samples"
                    />
                    <i
                      class="fa-kit fa-variety-cone"
                      v-if="hasHops"
                      title="This session includes hops samples"
                    ></i>
                    <y-icon
                      icon="circle-question-solid"
                      v-if="hasOther"
                      font-size="1rem"
                      :title="'This session includes ' + otherType.toLowerCase() + ' samples'"
                    />
                  </div>
                </div>

                <p class="panel-session-modal__panel-description">
                  {{ panelSession.notes }}
                </p>
              </div>
              <div class="panel-session-modal__actions grid grid-cols-2">
                <YButton
                  class="panel-session-modal__actions__button"
                  label="Remove"
                  icon="trash-solid"
                  background-color="primary"
                  size="md"
                  width="100%"
                  @click="removePanelSession(panelSession)"
                />

                <YButton
                  class="panel-session-modal__actions__button"
                  label="Close"
                  icon="xmark"
                  iconPosition="right"
                  background-color="secondary"
                  size="md"
                  width="100%"
                  @click="setIsOpen(false)"
                />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { YButton, YIcon } from '@yakimachief/ych-ts-component-library'
import { usePanelSessionStore } from '@/stores/PanelSessionStore'
import moment from 'moment'

import type { PanelSession } from '@/types/PanelSession'
import { computed } from 'vue'

const props = defineProps<{
  panelSession: PanelSession
  open: boolean
}>()

const emit = defineEmits(['update:open'])

const panelSessionStore = usePanelSessionStore()

const setIsOpen = function (openState: boolean) {
  emit('update:open', openState)
}

const removePanelSession = function (panelSession: PanelSession) {
  panelSessionStore.removePanelSession(panelSession.id)
  setIsOpen(false)
}

const hasBeer = computed(() => {
  return props.panelSession.types.includes('beer')
})
const hasHops = computed(() => {
  return props.panelSession.types.includes('hops')
})
const hasOther = computed(() => {
  return otherTypes.value.length > 0
})
const otherTypes = computed(() => {
  return props.panelSession.types.filter((type) => {
    return !(type === 'hops' || type === 'beer')
  })
})

const otherType = computed(() => {
  return otherTypes.value.join(', ')
})
</script>
<style lang="scss">
.panel-session-modal {
  border-radius: 6px;

  &__background {
    background-color: var(--ych-grey-200);
    opacity: 75%;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  &__heading {
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--ych-grey-200);
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  &__datetime {
    font-size: var(--y-font-size-md);
    font-weight: var(--y-font-weight-bold);
    text-transform: uppercase;
  }

  &__panel-session-id {
    font-size: var(--y-font-size-md);
    color: var(--ych-grey-400);
  }

  &__location {
    font-size: var(--y-font-size-md);

    &__name {
      margin-right: 0.5rem;
    }
  }

  &__panel-name {
    font-size: var(--y-font-size-md);
    font-weight: var(--y-font-weight-bold);

    text-align: center;
  }

  &__panel-description {
    line-height: 28px;
  }

  &__dates {
    text-align: center;
    font-weight: var(--y-font-weight-bold);
    font-size: var(--y-font-size-md);
  }

  &__actions {
    padding-top: 1rem;
    border-top: 1px solid var(--ych-grey-200);
    display: flex;
    gap: 1rem;
  }
}
</style>
