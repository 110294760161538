import { ref } from 'vue'
import type { Ref } from 'vue'
import type { PanelSession } from '@/types/PanelSession'

const validColumns = ['date-time', 'session-type', 'location']

type PanelSessionsSortField = 'date-time' | 'session-type' | 'location'
type PanelSessionsSortDirection = 'asc' | 'desc'
type PanelSessionsFilterType = null | 'beer' | 'hops' | 'other' | 'mixed'
type PanelSessionsFilterSettings = {
  currentSortField: PanelSessionsSortField
  currentSortDirection: PanelSessionsSortDirection
  filterType: PanelSessionsFilterType[]
  search: string
}

const sessionsFilterSettings: Ref<PanelSessionsFilterSettings> = ref({
  currentSortField: 'date-time',
  currentSortDirection: 'asc',
  filterType: [],
  search: ''
})

const getPanelTypeLabel = function (panelSession: PanelSession): string {
  if (panelSession.types.length === 1) {
    const type = panelSession.types[0]
    return type.charAt(0).toUpperCase() + type.slice(1)
  } else {
    return 'Mixed'
  }
}

export { validColumns, sessionsFilterSettings, getPanelTypeLabel }
export type {
  PanelSessionsFilterSettings,
  PanelSessionsSortField,
  PanelSessionsSortDirection,
  PanelSessionsFilterType
}
