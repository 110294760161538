import { customRef } from 'vue'

export default function (key: string, defaultValue: string | null) {
  return customRef((track, trigger) => ({
    get: (): string => {
      track()
      const value: string | null = localStorage.getItem(key)
      return value ? JSON.parse(value) : defaultValue
    },
    set: (value) => {
      if (value === null) {
        localStorage.removeItem(key)
      } else {
        localStorage.setItem(key, JSON.stringify(value))
      }
      trigger()
    }
  }))
}
