import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import AboutView from '../views/AboutView.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('../views/ProfileView.vue')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('../views/AboutView.vue')
    },
    {
      path: '/test/:testCode',
      name: 'test',
      props: true,
      component: () => import('../views/TestView.vue')
    },
    {
      path: '/panel/:panelId',
      name: 'panel',
      props: true,
      component: () => import('../views/PanelView.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'FileNotFound',
      component: () => import('../views/FileNotFoundView.vue')
    }
  ]
})

export default router
