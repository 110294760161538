import { defineStore } from 'pinia'
import type { PanelSession } from '@/types/PanelSession'
import axios from 'axios'
import router from '@/router'
import useToasterStore, { errorToast, successToast, quickToast } from '@/stores/ToasterStore'
import moment from 'moment/moment'
import type { Panel } from '@/types/Panel'

type PanelSessionStoreLoadingStatus = {
  available: boolean
  availableFetching: boolean
  scheduled: boolean
  scheduledFetching: boolean
  history: boolean
  historyFetching: boolean
}

type PanelSessionStoreData = {
  availablePanelSessions: PanelSession[]
  scheduledPanelSessions: PanelSession[]
  panelSessionsHistory: PanelSession[]
  loaded: PanelSessionStoreLoadingStatus
  panelSessionIcalFeed: string
}

const castDatesFromApi = function (apiPanelSessionData: any): PanelSession {
  const newPanelSession = apiPanelSessionData
  newPanelSession.startTime = new Date(newPanelSession.startTime)
  newPanelSession.endTime = new Date(newPanelSession.endTime)
  newPanelSession.createdAt = new Date(newPanelSession.createdAt)
  newPanelSession.modifiedAt = new Date(newPanelSession.modifiedAt)
  return newPanelSession
}

const usePanelSessionStore = defineStore('PanelSessionsStore', {
  state: (): PanelSessionStoreData => {
    return {
      availablePanelSessions: [],
      scheduledPanelSessions: [],
      panelSessionsHistory: [],
      panelSessionIcalFeed: '',
      loaded: {
        available: false,
        availableFetching: false,
        scheduled: false,
        scheduledFetching: false,
        history: false,
        historyFetching: false
      }
    }
  },
  actions: {
    async fetchAvailableSessions() {
      this.loaded.available = false
      this.loaded.availableFetching = true
      return axios
        .get('/api/panel-sessions/upcoming')
        .then((response) => {
          let data = response.data.data.availablePanelSessions
          data = data.map(castDatesFromApi)
          this.availablePanelSessions = data
          this.loaded.available = true
        })
        .catch((error) => {
          switch (error.response?.status) {
            case 401:
              router.push('/login')
              break
            case 422:
              errorToast('Fetch Sessions Error', error.response.data.message)
              break
            default:
              if (error.response?.data?.message)
                errorToast(error.message, error.response.data.message)
              else errorToast(error.name, error.message)
              console.error(error)
          }
          if (error.response) return error.response
        })
        .finally(() => {
          this.loaded.availableFetching = false
        })
    },
    async fetchUserPanelSessions() {
      this.loaded.scheduled = false
      this.loaded.scheduledFetching = true
      return axios
        .get('/api/current-user/panel-sessions')
        .then((response) => {
          const data = response.data.data.panelSessions
          this.panelSessionIcalFeed = response.data.data.panelSessionIcalFeed ?? null
          this.scheduledPanelSessions = data.map(castDatesFromApi)
          this.loaded.scheduled = true
          return response
        })
        .catch((error) => {
          switch (error.response?.status) {
            case 401:
              router.push('/login')
              break
            case 422:
              errorToast('Fetch User Sessions Error', error.response.data.message)
              break
            default:
              if (error.response?.data?.message)
                errorToast(error.message, error.response.data.message)
              else errorToast(error.name, error.message)
              console.error(error)
          }
          if (error.response) return error.response
        })
        .finally(() => {
          this.loaded.scheduledFetching = false
        })
    },
    async fetchUserPanelSessionHistory() {
      this.loaded.history = false
      this.loaded.historyFetching = true
      return axios
        .get('/api/current-user/panel-sessions/history')
        .then((response) => {
          let data = response.data.data.panelSessions
          data = data.map(castDatesFromApi)
          this.panelSessionsHistory = data
          this.loaded.history = true
        })
        .catch((error) => {
          switch (error.response?.status) {
            case 401:
              router.push('/login')
              break
            case 422:
              errorToast('Fetch Session History Error', error.response.data.message)
              break
            default:
              if (error.response?.data?.message)
                errorToast(error.message, error.response.data.message)
              else errorToast(error.name, error.message)
              console.error(error)
          }
          if (error.response) return error.response
        })
        .finally(() => {
          this.loaded.historyFetching = false
        })
    },
    async addPanelSession(panelSessionId: number) {
      return axios
        .post('/api/current-user/panel-sessions/add', {
          panel_session_id: panelSessionId
        })
        .then(async (response) => {
          if (response.status === 200) {
            await Promise.all([this.fetchAvailableSessions(), this.fetchUserPanelSessions()])
            const message =
              'Your sensory session will begin ' +
              moment(response.data.data.panelSession.startTime).format(
                'dddd, MMMM Do [at] h:mm a'
              ) +
              '.'
            quickToast('Session Added to Calendar', message, 'success')
            return response
          }
        })
        .catch((error) => {
          switch (error.response?.status) {
            case 401:
              router.push('/login')
              break
            case 422:
              if (error.response.data.errors.panel_session_id?.length > 1) {
                error.response.data.errors.panel_session_id.forEach((errorMessage: string) =>
                  errorToast('Add Session Error', errorMessage)
                )
              } else errorToast('Add Session Error', error.response.data.message)
              break
            default:
              if (error.response?.data?.message)
                errorToast(error.message, error.response.data.message)
              else errorToast(error.name, error.message)
              console.error(error)
          }
          this.loaded.scheduled = true
          this.loaded.scheduledFetching = false
          this.loaded.available = true
          this.loaded.availableFetching = false
          if (error.response) return error.response
        })
    },
    async removePanelSession(panelSessionId: number) {
      this.loaded.scheduled = false
      this.loaded.scheduledFetching = true
      this.loaded.available = false
      this.loaded.availableFetching = true
      return axios
        .post('/api/current-user/panel-sessions/remove', {
          panel_session_id: panelSessionId
        })
        .then(async (response) => {
          if (response.status === 200) {
            await Promise.all([this.fetchAvailableSessions(), this.fetchUserPanelSessions()])
            quickToast(
              'Session Removed from Calendar',
              'You are no longer registered for this session.',
              'warning'
            )
          }
        })
        .catch((error) => {
          switch (error.response?.status) {
            case 401:
              router.push('/login')
              break
            case 422:
              if (error.response.data.errors.panel_session_id?.length > 1) {
                error.response.data.errors.panel_session_id.forEach((errorMessage: string) =>
                  errorToast('Remove Session Error', errorMessage)
                )
              } else errorToast('Remove Session Error', error.response.data.message)
              break
            default:
              if (error.response?.data?.message)
                errorToast(error.message, error.response.data.message)
              else errorToast(error.name, error.message)
              console.error(error)
          }
          this.loaded.scheduled = true
          this.loaded.scheduledFetching = false
          this.loaded.available = true
          this.loaded.availableFetching = false
          if (error.response) return error.response
        })
    },
    async switchPanelSession(panelSessionId: number) {
      this.loaded.scheduled = false
      this.loaded.scheduledFetching = true
      this.loaded.available = false
      this.loaded.availableFetching = true
      return axios
        .post('/api/current-user/panel-sessions/switch', {
          panel_session_id: panelSessionId
        })
        .then(async (response) => {
          if (response.status === 200) {
            await Promise.all([this.fetchAvailableSessions(), this.fetchUserPanelSessions()])
            const message =
              'Your sensory session will now begin ' +
              moment(response.data.data.panelSession.startTime).format(
                'dddd, MMMM Do [at] h:mm a'
              ) +
              '.'
            quickToast('Session Swapped', message, 'info')
          }
          return response
        })
        .catch((error) => {
          switch (error.response?.status) {
            case 401:
              router.push('/login')
              break
            case 422:
              if (error.response.data.errors.panel_session_id?.length > 1) {
                error.response.data.errors.panel_session_id.forEach((errorMessage: string) =>
                  errorToast('Switch Session Error', errorMessage)
                )
              } else errorToast('Switch Session Error', error.response.data.message)
              break
            default:
              if (error.response?.data?.message) {
                errorToast(error.message, error.response.data.message)
              } else errorToast(error.name, error.message)
              console.error(error)
          }
          this.loaded.scheduled = true
          this.loaded.scheduledFetching = false
          this.loaded.available = true
          this.loaded.availableFetching = false
          if (error.response) return error.response
        })
    }
  },
  getters: {
    panels: (state): Panel[] => {
      if (state.availablePanelSessions) {
        const out: Panel[] = []
        state.availablePanelSessions.forEach((panelSession) => {
          const panelIndex = out.findIndex((panel) => panel.id == panelSession.panelId)
          if (panelIndex === -1) {
            out.push({
              id: panelSession.panelId,
              name: panelSession.panelName,
              panelSessions: [panelSession]
            })
          } else {
            out[panelIndex].panelSessions?.push(panelSession)
          }
        })
        return out
      }
      return []
    }
  }
})

export { usePanelSessionStore, castDatesFromApi }
