<template>
  <div
    :class="[
      panelSessionStore.loaded.scheduledFetching ? 'opacity-20' : '',
      'user-schedule lg:flex lg:h-full lg:flex-col relative'
    ]"
  >
    <UserScheduleHeader :ical-feed-url="panelSessionStore.panelSessionIcalFeed ?? ''" />
    <div class="lg:flex lg:flex-auto lg:flex-col">
      <UserScheduleMonthWeekdays />
      <div class="user-schedule__calendar-box flex text-xs leading-6 lg:flex-auto">
        <UserScheduleMonth :days="days" />
        <UserScheduleMonthMobile :days="days" />
      </div>
    </div>
    <UserScheduleMobileSelectedDay :selected-day="selectedDay" />
    <CardSpinner v-if="panelSessionStore.loaded.scheduledFetching" />
  </div>
</template>
<script setup lang="ts">
import UserScheduleHeader from './UserScheduleHeader.vue'
import {
  scheduleFocusDate,
  scheduleSelectedDate
} from '@/components/UserSchedule/userScheduleComposables'
import { usePanelSessionStore } from '@/stores/PanelSessionStore'
import { computed } from 'vue'
import UserScheduleMonthMobile from '@/components/UserSchedule/UserScheduleMonthMobile.vue'
import UserScheduleMobileSelectedDay from '@/components/UserSchedule/UserScheduleMobileSelectedDay.vue'
import UserScheduleMonth from '@/components/UserSchedule/UserScheduleMonth.vue'
import UserScheduleMonthWeekdays from '@/components/UserSchedule/UserScheduleMonthWeekdays.vue'

import type { UserScheduleDay } from '@/types/UserScheduleDay'
import type { PanelSession } from '@/types/PanelSession'
import CardSpinner from '@/components/CardSpinner.vue'

const panelSessionStore = usePanelSessionStore()
panelSessionStore.fetchUserPanelSessions()

const days = computed(() => {
  var startOfMonth = scheduleFocusDate.value.clone().startOf('month')
  var startOfMonthWeek = startOfMonth.clone().startOf('week')
  var endOfMonth = scheduleFocusDate.value.clone().endOf('month')
  var endOfMonthWeek = endOfMonth.clone().endOf('week').subtract(1, 'day')
  var days: UserScheduleDay[] = []
  var numberOfWeeks = endOfMonthWeek.diff(startOfMonthWeek, 'week')
  if (numberOfWeeks < 5) {
    endOfMonthWeek.add(1, 'week')
  }
  for (
    var cDay = startOfMonthWeek.clone();
    cDay.diff(endOfMonthWeek, 'days') <= 0;
    cDay.add(1, 'days')
  ) {
    let day = {
      date: cDay.format('YYYY-MM-DD'),
      isCurrentMonth: cDay.isSame(startOfMonth, 'month'),
      isToday: cDay.isSame(new Date(), 'day'),
      isSelected: scheduleSelectedDate.value
        ? cDay.isSame(scheduleSelectedDate.value.toDate(), 'day')
        : false,
      panelSessions: [] as PanelSession[]
    }
    panelSessionStore.scheduledPanelSessions.forEach((panelSession: PanelSession) => {
      if (cDay.isSame(panelSession.startTime, 'day')) {
        day.panelSessions.push(panelSession)
      }
    })
    days.push(day)
  }
  return days
})
const selectedDay = computed(() => {
  return days.value.find((day: any) => day.isSelected)
})
</script>

<style lang="scss">
.user-schedule {
  &__calendar-box {
    background: var(--ych-grey-400);
    border-radius: var(--y-radius-md);
    overflow: hidden;
  }

  &__calendar-inner-box {
    border-style: solid;
    border-color: var(--ych-grey-400);
    border-width: 1px;
    border-radius: var(--y-radius-md);
    overflow: hidden;
  }

  &__placeholder {
    background: var(--ych-white);
  }

  &__day {
    color: var(--primary-accent-color);
    background: var(--ych-grey-600);
    min-height: 7rem;
    font-weight: var(--y-font-weight-bold);
    overflow: auto;
    max-height: 8.5rem;
  }

  &__day--current-month {
    color: var(--secondary-brand-color);
    background: var(--ych-white);
  }

  &__day {
    &--today {
      background: var(--ych-grey-200);
    }

    &__number {
      padding-left: 0.25rem;
    }

    &__session-list {
      text-align: right;
      line-height: 1rem;

      &__item {
        border-radius: 1rem;
        color: var(--ych-white);
        padding-right: 5px;
        margin: 3px;

        &--reg {
          background-color: var(--secondary-brand-color);
        }

        &--live {
          background-color: var(--primary-brand-color);
        }

        &--null {
          background-color: var(--ych-kraft);
        }
      }
    }
  }
}
</style>
