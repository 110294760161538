<template>
  <div
    class="grid grid-cols-7 gap-px bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none"
  >
    <div class="bg-white py-2">S<span class="sr-only sm:not-sr-only">un</span></div>
    <div class="bg-white py-2">M<span class="sr-only sm:not-sr-only">on</span></div>
    <div class="bg-white py-2">T<span class="sr-only sm:not-sr-only">ue</span></div>
    <div class="bg-white py-2">W<span class="sr-only sm:not-sr-only">ed</span></div>
    <div class="bg-white py-2">T<span class="sr-only sm:not-sr-only">hu</span></div>
    <div class="bg-white py-2">F<span class="sr-only sm:not-sr-only">ri</span></div>
    <div class="bg-white py-2">S<span class="sr-only sm:not-sr-only">at</span></div>
  </div>
</template>
