import { defineStore } from 'pinia'
import axios from 'axios'
import type { Organization } from '@/types/Organization'
import router from '@/router'
import { errorToast } from '@/stores/ToasterStore'
import organizationFactory from '@/stores/Factories/OrganizationFactory'
import type { OrganizationTheme } from '@/types/OrganizationTheme'

type OrganizationStoreData = {
  currentOrganization: Organization | null
  availableOrganizations: Organization[]
}

const castApiOrganizationResponseToOrganization = function (organizationData: any): Organization {
  return organizationFactory(organizationData)
}

export const useOrganizationStore = defineStore('OrganizationStore', {
  state: (): OrganizationStoreData => ({
    currentOrganization: null,
    availableOrganizations: []
  }),

  actions: {
    applyTheme() {
      const organizationStore = useOrganizationStore()
      document.documentElement.style.setProperty(
        '--primary-brand-color',
        organizationStore.getCurrentOrganizationTheme().primaryBrandColor
      )
      document.documentElement.style.setProperty(
        '--secondary-brand-color',
        organizationStore.getCurrentOrganizationTheme().secondaryBrandColor
      )
      document.documentElement.style.setProperty(
        '--primary-accent-color',
        organizationStore.getCurrentOrganizationTheme().primaryAccentColor
      )
      document.documentElement.style.setProperty(
        '--secondary-accent-color',
        organizationStore.getCurrentOrganizationTheme().secondaryAccentColor
      )
    },
    getCurrentOrganizationTheme() {
      let theme: OrganizationTheme

      if (this.currentOrganization?.theme) {
        theme = this.currentOrganization?.theme
      } else {
        theme = {
          primaryBrandColor: '#d22630',
          secondaryBrandColor: '#002B49',
          primaryAccentColor: '#FFFFFF',
          secondaryAccentColor: '#D2AB89'
        }
      }

      return theme
    },
    async getOrganizations() {
      return axios
        .get('/api/current-user/organizations')
        .then((response) => {
          const responseData = response.data.data
          this.currentOrganization = castApiOrganizationResponseToOrganization(
            responseData.currentOrganization
          )
          this.availableOrganizations = []
          responseData.availableOrganizations.forEach((organizationData: any) => {
            this.availableOrganizations.push(
              castApiOrganizationResponseToOrganization(organizationData)
            )
          })
        })
        .catch((error) => {
          switch (error.response?.status) {
            case 401:
              router.push('/login')
              break
            default:
              if (error.response?.data?.message)
                errorToast(error.message, error.response.data.message)
              else errorToast(error.name, error.message)
              console.error(error)
          }
          if (error.response) return error.response
        })
        .finally(() => {
          // always executed
        })
    },
    async switchOrganization(organization: Organization) {
      return axios
        .get('/api/current-user/organization/' + organization.uuid + '/activate')
        .then((response) => {
          this.currentOrganization = castApiOrganizationResponseToOrganization(response.data.data)
        })
        .catch((error) => {
          switch (error.response?.status) {
            case 401:
              router.push('/login')
              break
            default:
              if (error.response?.data?.message)
                errorToast(error.message, error.response.data.message)
              else errorToast(error.name, error.message)
              console.error(error)
          }
          if (error.response) return error.response
        })
    }
  }
})
