<template>
  <Teleport to="body">
    <Transition name="toast">
      <div v-if="toastStore.toasts.length" class="toaster__wrapper">
        <TransitionGroup name="toast" tag="ul">
          <li
            v-for="toast in toastStore.toasts"
            :class="['toaster__inner', toastClassMap[toast.status]]"
            :key="toast.text"
          >
            <YIcon :icon="toastIconMap[toast.status]" class="toaster__inner__icon" />

            <main>
              <h4 class="toaster__inner__title">
                {{ toast.title }}
              </h4>
              <p class="toaster__inner__text">
                {{ toast.text }}
              </p>
              <div class="toaster__inner__buttons">
                <y-button
                  v-for="button in toast.buttons"
                  :key="button.label"
                  @click="buttonClick(button, toast)"
                  :label="button.label"
                  :backgroundColor="button.isPrimary ? 'primary' : 'transparent'"
                  :mobileFill="false"
                >
                </y-button>
              </div>
            </main>
            <div @click="removeToast(toast)" class="toaster__inner__close">
              <YIcon icon="xmark" />
            </div>
          </li>
        </TransitionGroup>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
import useToasterStore from '@/stores/ToasterStore'
import type { ToastStatus } from '@/types/ToastStatus'
import type { ToastButton } from '@/types/ToastButton'
import type { Toast } from '@/types/Toast'
import { YIcon, YButton } from '@yakimachief/ych-ts-component-library'
import { type validIconsType } from '@yakimachief/ych-ts-component-library/dist/types/components/YIcon/YIconLibrary'

const toastClassMap: Record<ToastStatus, string> = {
  warning: 'warning',
  info: 'info',
  error: 'error',
  success: 'success'
}

const toastIconMap: Record<ToastStatus, validIconsType> = {
  error: 'circle-exclamation',
  warning: 'triangle-exclamation',
  info: 'circle-info',
  success: 'circle-check'
}
const toastStore = useToasterStore()

function buttonClick(button: ToastButton, toast: Toast) {
  button.action()
  if (button.closeOnClick) {
    toastStore.removeToast(toast.id)
  }
}

function removeToast(toast: Toast) {
  toastStore.removeToast(toast.id)
}
</script>

<style lang="scss">
.toaster__inner {
  &.info {
    --prime-btn-bg: var(--primary-brand-color);
    --prime-btn-hover-bg: var(--ych-red-dark);
    --prime-btn-shadow: var(--y-shadow-red-md);
    --trans-btn-hover-bg: rgba(255, 255, 255, 0.2);
    --trans-btn-color: var(--primary-accent-color);
    --trans-btn-shadow: var(--y-shadow-grey-md);
  }

  &.warning {
    --prime-btn-bg: var(--secondary-brand-color);
    --prime-btn-hover-bg: var(--ych-blue-light);
    --prime-btn-shadow: var(--y-shadow-blue-md);
    --trans-btn-hover-bg: rgba(255, 255, 255, 0.2);
    --trans-btn-color: var(--secondary-brand-color);
    --trans-btn-shadow: var(--y-shadow-grey-md);
  }

  &.success {
    --prime-btn-bg: var(--secondary-brand-color);
    --prime-btn-hover-bg: var(--ych-blue-light);
    --prime-btn-shadow: var(--y-shadow-blue-md);
    --trans-btn-hover-bg: rgba(0, 0, 0, 0.1);
    --trans-btn-color: var(--primary-accent-color);
    --trans-btn-shadow: var(--y-shadow-grey-md);
  }

  &.error {
    --prime-btn-bg: var(--secondary-brand-color);
    --prime-btn-hover-bg: var(--ych-blue-light);
    --prime-btn-shadow: var(--y-shadow-blue-md);
    --trans-btn-hover-bg: rgba(0, 0, 0, 0.1);
    --trans-btn-color: var(--primary-accent-color);
    --trans-btn-shadow: var(--y-shadow-grey-md);
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.75rem;

    .y-button {
      min-width: 80px;
      max-width: 50%;

      &__button {
        &--primary {
          background-color: var(--prime-btn-bg);

          &:hover {
            box-shadow: var(--prime-btn-shadow);
            background-color: var(--prime-btn-hover-bg);
          }

          &:active {
            box-shadow: var(--prime-btn-shadow);
            background-color: var(--prime-btn-hover-bg);
          }
        }

        &--transparent {
          color: var(--trans-btn-color);

          &:hover {
            box-shadow: var(--trans-btn-shadow);
            background-color: var(--trans-btn-hover-bg);
          }

          &:active {
            box-shadow: var(--trans-btn-shadow);
            background-color: var(--trans-btn-hover-bg);
          }
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
.toast-enter-from,
.toast-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.toast-enter-active,
.toast-leave-active {
  transition: 0.25s ease all;
}

.toaster {
  &__wrapper {
    position: fixed;
    bottom: 3%;
    right: 5%;
    left: auto;
    width: 400px;
    max-width: 100%;

    z-index: 100;

    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__inner {
    margin-bottom: 1rem;
    padding: 0.75rem 0.75rem 1rem 0.75rem;
    gap: 0.75rem;
    background-color: var(--accent-color);
    border-radius: 4px;
    box-shadow:
      0px 2px 4px 0px rgba(40, 41, 40, 0.18),
      0px 4px 8px 2px rgba(40, 41, 40, 0.1),
      0px 8px 16px 4px rgba(40, 41, 40, 0.04);

    display: grid;
    grid-template-columns: 30px 1fr 30px;

    svg,
    svg path {
      color: var(--text-color);
      fill: var(--text-color);
      stroke: var(--text-color);
    }

    &.success {
      --accent-color: var(--ych-green);
      --text-color: #ffffff;
      --hover-bg: rgba(0, 0, 0, 0.1);
      --close-btn-color: #ffffff;
    }

    &.info {
      --accent-color: var(--secondary-brand-color);
      --text-color: #ffffff;
      --hover-bg: rgba(255, 255, 255, 0.2);
      --close-btn-color: #ffffff;
    }

    &.warning {
      --accent-color: #ffc107;
      --text-color: var(--secondary-brand-color);
      --hover-bg: rgba(255, 255, 255, 0.2);
      --close-btn-color: var(--secondary-brand-color);
    }

    &.error {
      --accent-color: var(--primary-brand-color);
      --text-color: #ffffff;
      --hover-bg: rgba(0, 0, 0, 0.1);
      --close-btn-color: #ffffff;
    }

    &__icon {
      aspect-ratio: 1/1;
      width: 1.25rem;
      height: 1.25rem;
      padding-top: 0.25rem;
    }

    &__close {
      aspect-ratio: 1/1;
      font-size: 1.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: var(--y-radius-sm);
      color: var(--close-btn-color);
      padding: 0.125rem;
    }

    &__close:hover {
      cursor: pointer;
      background: var(--hover-bg);
    }

    &__title {
      color: var(--text-color);
      font-family: var(--y-font-family-sans-serif);
      text-transform: none;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem; /* 125% */
      letter-spacing: 0.08px;
      margin-bottom: 0.75rem;
      padding-top: 0.25rem;
    }

    &__text {
      color: var(--text-color);
      font-family: Roboto;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem; /* 150% */
      letter-spacing: 0.08px;
      margin-bottom: 0.75rem;
      -webkit-hyphens: manual;
      -moz-hyphens: manual;
      -ms-hyphens: manual;
      hyphens: manual;
    }
  }
}

@media (max-width: 640px) {
  .toaster__wrapper {
    right: 0;
    left: 0;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
</style>
