<script setup lang="ts">
import { ref, computed } from 'vue'
import { vOnClickOutside } from '@vueuse/components'
import { useOrganizationStore } from '@/stores/OrganizationStore'
import OrganizationSwitcherItem from '@/components/OrganizationSwitcher/OrganizationSwitcherItem.vue'
import type { Organization } from '@/types/Organization'
import { useRouter } from 'vue-router'
import { YIcon } from '@yakimachief/ych-ts-component-library'

interface Props {
  disableSwitcher?: boolean | null
}

const props = withDefaults(defineProps<Props>(), {
  disableSwitcher: false
})

const router = useRouter()

const organizationStore = useOrganizationStore()
organizationStore.getOrganizations()
const switcherButtonIcon = computed(() => {
  if (displaySwitcherMenu.value) return 'chevron-down'
  return 'chevron-right'
})
const displaySwitcherMenu = ref(false)

const handleMenuClick = function () {
  if (!props.disableSwitcher) {
    displaySwitcherMenu.value = !displaySwitcherMenu.value
  }
}

const handleSwitch = (organization: Organization) => {
  organizationStore.switchOrganization(organization).then(() => {
    router.go(0)
  })
}
</script>

<template>
  <div
    v-on-click-outside="() => (displaySwitcherMenu = false)"
    class="org-switcher"
    v-if="organizationStore.availableOrganizations.length > 0"
  >
    <button
      class="org-switcher__button"
      :class="{
        unclickable: disableSwitcher
      }"
      @click="handleMenuClick"
    >
      <span class="org-switcher__button__heading h2 pl-4">{{
        organizationStore.currentOrganization?.name
      }}</span>
      <template v-if="!disableSwitcher">
        <YIcon
          v-if="displaySwitcherMenu"
          class="org-switcher__button__icon org-switcher__button__icon--chevron"
          icon="chevron-up"
        />
        <YIcon
          class="org-switcher__button__icon org-switcher__button__icon--chevron"
          v-else
          icon="chevron-down"
        />
        <YIcon
          class="org-switcher__button__icon org-switcher__button__icon--building"
          icon="building"
        />
      </template>
      <template v-else>
        <YIcon class="org-switcher__button__icon org-switcher__button__icon--lock" icon="lock" />
      </template>
    </button>
    <div class="org-switcher__content flex flex-col" v-if="displaySwitcherMenu">
      <OrganizationSwitcherItem
        v-for="organization in organizationStore.availableOrganizations"
        :organization="organization"
        class="org-switcher__item"
        @click="handleSwitch(organization)"
      />
    </div>
  </div>
</template>

<style lang="scss">
.org-switcher svg path {
  fill: var(--primary-accent-color);
}
</style>
<style scoped lang="scss">
.org-switcher {
  min-width: 2rem;
  &__button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.25rem;
    color: var(--primary-accent-color);
    background-color: transparent;
    background-image: none;

    &.unclickable {
      cursor: default;
    }

    &__heading {
      color: var(--primary-accent-color);
      display: none;
      border-left: 1px solid var(--primary-accent-color);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @media (min-width: 640px) {
        display: block;
      }
    }

    &__icon {
      font-size: 1.125rem;

      &--lock {
        display: block;
      }

      &--building {
        display: block;
        @media (min-width: 640px) {
          display: none;
        }
      }

      &--chevron {
        display: none;
        @media (min-width: 640px) {
          display: block;
        }
      }
    }
  }

  &__content {
    position: absolute;
    right: auto;
    left: 0rem;
    top: 3.25rem;
    width: auto;
    max-width: 22rem;
    background: var(--primary-brand-color);
    overflow: hidden;
    border-radius: 0 0 var(--y-radius-md) var(--y-radius-md);
    box-shadow: var(--y-shadow-grey-md);
    z-index: 9999;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media (min-width: 640px) {
      right: 0rem;
      left: auto;
      top: 3.1875rem;
    }
  }
}
</style>
