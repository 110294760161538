import { defineStore } from 'pinia'
import { useUserStore } from '@/stores/UserStore'
import { usePanelSessionStore } from '@/stores/PanelSessionStore'

export const useLoadingStore = defineStore('LoadingStore', {
  getters: {
    isLoading(state) {
      const userStore = useUserStore()
      const panelSessionStore = usePanelSessionStore()
      const loading =
        userStore.loaded.userFetching ||
        userStore.loaded.notesFetching ||
        userStore.loaded.referenceStandardsFetching ||
        userStore.loaded.metricsFetching ||
        panelSessionStore.loaded.scheduledFetching ||
        panelSessionStore.loaded.availableFetching
      return loading
    }
  }
})
