<script setup lang="ts">
import type { Organization } from '@/types/Organization'
import { YIcon } from '@yakimachief/ych-ts-component-library'
import { useOrganizationStore } from '@/stores/OrganizationStore'
import { computed } from 'vue'

const organizationStore = useOrganizationStore()

const props = defineProps<{
  organization: Organization
}>()

const active = computed(() => {
  return props.organization.uuid === organizationStore.currentOrganization?.uuid
})
</script>

<template>
  <button :class="[{ 'org-switcher-item--active': active }, 'flex org-switcher-item']">
    <span class="org-switcher-item__status-display">
      <YIcon v-if="active" icon="check" class="org-switcher-item__status-display__icon" />
    </span>
    <h3 class="org-switcher-item__org-name">{{ organization.name }}</h3>
  </button>
</template>

<style scoped lang="scss">
.org-switcher-item {
  color: var(--primary-accent-color);
  padding: 1rem;
  background-color: var(--primary-brand-color);
  font-size: var(--y-font-size-lg);
  font-weight: var(--y-font-weight-bold);
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 0.75rem;

  &--active {
    color: var(--primary-accent-color);
    background-color: var(--primary-brand-color);
  }

  &__status-display {
    width: 1rem;
    font-size: var(--y-font-size-md);
    font-weight: var(--y-font-weight-regular);

    &__icon {
      color: var(--primary-accent-color);
    }
  }
  &__org-name {
    display: inline-block;
    width: 100%;
    text-align: left;
    color: var(--primary-accent-color);
    text-transform: uppercase;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &:hover {
    background: var(--primary-accent-color);
    color: var(--primary-brand-color);
    .org-switcher-item__status-display__icon,
    .org-switcher-item__org-name {
      color: var(--primary-brand-color);
    }
  }
}
</style>
