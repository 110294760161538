<script setup lang="ts">
// Leaderboard Variables
import { computed, type Ref, ref } from 'vue'
import { YDropdown } from '@yakimachief/ych-ts-component-library'
import { useLeaderboardStore } from '@/stores/LeaderboardStore'
import type { Leaderboard } from '@/types/Leaderboard'
import { useUserStore } from '@/stores/UserStore'

const selectedLeaderboard: Ref<Leaderboard | undefined> = ref()

const userStore = useUserStore()

const metrics = computed(() => {
  return userStore.currentUserMetrics
})

const leaderboardStore = useLeaderboardStore()
leaderboardStore.fetchLeaderboards()
leaderboardStore.fetchLeaderboards().then(() => {
  if (leaderboardDropdownOptions.value.length > 0) {
    selectedLeaderboard.value = leaderboardDropdownOptions.value[0]
  }
})

const leaderboardDropdownOptions = computed((): Leaderboard[] => {
  return leaderboardStore.leaderboards
})

const selectedLeaderboardResults = computed(() => {
  if (
    metrics.value &&
    metrics.value?.leaderboardStats &&
    metrics.value?.leaderboardStats.length > 0 &&
    selectedLeaderboard.value
  ) {
    let index: number = metrics.value?.leaderboardStats.findIndex(
      (leaderboard: Leaderboard) => leaderboard.id == selectedLeaderboard.value?.id
    )
    if (index >= 0) {
      return metrics.value?.leaderboardStats[index]
    }
  }
})

const place = computed(() => {
  return selectedLeaderboardResults.value?.currentUserAchievement?.place ?? null
})

const display = computed(() => {
  return selectedLeaderboardResults.value?.currentUserAchievement?.achievement ?? null
})
</script>

<template>
  <div class="leaderboard flex flex-col py-3 gap-4" v-if="leaderboardDropdownOptions.length > 0">
    <div class="leaderboard__top-row inline-flex gap-4 justify-between items-center">
      <h3>Leaderboard<br />Status</h3>
      <YDropdown
        v-model="selectedLeaderboard"
        :options="leaderboardDropdownOptions"
        placeholder="Select Leaderboard..."
      />
    </div>
    <div class="leaderboard__stats flex justify-center" v-if="place || display">
      <div
        class="leaderboard__stats__container inline-flex items-center gap-1.5 y-bg-color-grey py-2 px-3"
      >
        <div class="leaderboard__stats__container__icon y-color-grey bg-white">
          <i class="fa-solid fa-trophy" />
        </div>
        <span class="y-color-white flex divide-x border-white">
          <span class="pr-1.5" v-if="place">No. {{ place }}</span>
          <span class="pl-1.5" v-if="display">{{ display }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.leaderboard {
  &__stats {
    &__container {
      border-radius: var(--y-radius-md);

      &__icon {
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 50%;
        font-size: 0.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>
